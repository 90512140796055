import { TAG_TYPES, MENTIONS } from '../constants/types'; // Importing enumerations for tag types and mentions to maintain consistency and avoid magic strings.

/**
 * A utility hook for handling campaign-related logic, specifically for determining
 * tag types based on status and mentions based on person roles.
 */
export const useCampaignUtils = () => {
  /**
   * Retrieves the tag type corresponding to a given campaign status.
   *
   * @param {string} status - The status of the campaign (e.g., "not_started", "in_progress", "submitted").
   * @returns {string} - The tag type (e.g., "error", "warning", "success"), or the default tag type if the status is invalid.
   */
  const getTagType = (status) => {
    // Convert the status to uppercase and map it to a tag type using the TAG_TYPES enum.
    // If the status is not found in TAG_TYPES, return the default tag type.
    return TAG_TYPES[status?.toUpperCase()] || TAG_TYPES.DEFAULT;
  };

  /**
   * Retrieves the mention text corresponding to a given role or person type.
   *
   * @param {string} person - The role or description of the person (e.g., "Person in charge", "Review of").
   * @returns {string} - The corresponding mention text, or an empty string if no mention is found for the provided role.
   */
  const getMention = (person) => {
    // Use the MENTIONS enum to map the person's role to the corresponding mention text.
    // If the person's role is not found, return an empty string as the fallback.
    return MENTIONS[person] || "";
  };

  // Expose the utility functions for use in other components or hooks.
  return {
    getTagType,
    getMention,
  };
};
