<template>
  <div class="w-full flex items-center gap-2">
    <svn-pro-autocomplete
      vitest-id="template-single-input"
      v-model="campaignDraft.default_template"
      :label="$t('Select a template')"
      :items="interviewForms"
      :return-object="true"
      :error="error"
      item-title="title"
      item-value="id"
      class="w-full"
      :infiniteScrollLoading="infiniteScrollLoading"
      @intersect="loadMoreData"
    />

    <svn-pro-tooltip :text="$t('Preview')">
      <template #activator="{ props }">
        <svn-pro-icon-button
          v-bind="props"
          vitest-id="template-single-preview-button"
          :disabled="!campaignDraft?.default_template"
          icon="custom:mingcute:eye-2-line"
          color="onSurfaceVariant"
          variant="text"
          @click="openInterviewFormPreview"
        />
      </template>
    </svn-pro-tooltip>
  </div>

  <interview-template-preview
    v-show="!!interviewForm"
    :attach="true"
    ref="interviewTemplatePreviewRef"
    :is-deleted="interviewForm?.status === 'trashed'"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import { interviewFormApi } from '@/apis/interview_form.api';
import { useInterviewFormStore } from "@/store/interview-form.js";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import InterviewTemplatePreview from "@/components/interviewApp/template/Show/InterviewTemplatePreview.vue";

const props = defineProps({
  error: { type: Boolean, default: false },
});

const { fetchInterviewForm } = useInterviewFormStore()

const { campaignDraft } = storeToRefs(useCampaignDraftStore());
const { interviewForm } = storeToRefs(useInterviewFormStore())

const loading = ref(true);
const infiniteScrollLoading = ref(false);
const pagination = ref({})
const interviewForms = ref([])
const interviewTemplatePreviewRef = ref(null);

onMounted(async () => {
  const data = await fetchInterviewForms(1)
  interviewForms.value = data.interview_app_forms
  pagination.value = data.meta.pagination
  loading.value = false;
});

const fetchInterviewForms = async(page = 1) => {
  let formType = ''
  if (campaignDraft?.value?.campaign_type == 'Survey') {
    formType = 'InterviewApp::Forms::Templates::Survey'
  } else if (campaignDraft?.value?.campaign_type == 'Feedback') {
    formType = 'InterviewApp::Forms::Templates::Feedback'
  } else {
    formType = 'InterviewApp::Forms::Templates::OneToOne'
  }
  return await interviewFormApi.fetch(formType, page);
}

const openInterviewFormPreview = async () => {
  if (interviewForm?.value?.id !== campaignDraft?.value?.default_template?.id) {
    await fetchInterviewForm(campaignDraft?.value?.default_template?.id);
  }

  if (interviewTemplatePreviewRef.value) {
    interviewTemplatePreviewRef.value.dialog = true
  }
}

const loadMoreData = async() => {
  if (pagination.value?.current_page < pagination.value?.total_pages) {
    infiniteScrollLoading.value = true
    pagination.value.current_page = pagination.value.current_page + 1
    const data = await fetchInterviewForms(pagination.value.current_page)
    interviewForms.value = [...interviewForms.value, ...data.interview_app_forms]
    pagination.value = data.meta.pagination
    infiniteScrollLoading.value = false;
  }
}
</script>