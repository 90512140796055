<template>
  <svn-pro-data-table
    :headers="interviewData?.length ? headers : []"
    :items="interviewData ?? []"
    :items-per-page="-1"
    :loading="interviewData ? loading : true"
    :pagination-total-pages="paginationData?.total_pages"
    item-value="id"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr
        class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]"
        data-testid="table-row"
        @click="goToSpecificInterview(item?.id)"
      >
        <td>
          <svn-pro-data-table-cell
            :emoji="campaign_icon(item.campaign.campaign_type)"
            :text="item?.campaign?.title"
            body-medium
            cell-type="emoji_text"
            regular
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            :anonym="item?.survey_person_in_charge?.length > 1"
            :avatar="getAvatar(item, tab)"
            :text="getText(item, tab)"
            body-medium
            cell-type="avatar_text"
            regular
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            :tag-type="getDynamicTagType(item)"
            :text="uppercaseFirst($t(getLabel(item)))"
            body-medium
            cell-type="info_tag"
            data-testid="info_tag"
            leading-item="point"
            regular
            tag-size="compact"
          />
        </td>
      </tr>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        :size="isMdScreen ? EMPTY_STATES_SIZE.DEFAULT : EMPTY_STATES_SIZE.COMPACT"
        :supporting-text="
          searchText
            ? $t('Oops, we didn’t find any results matching your search.')
            : $t(`Hmm, it seems you have no campaigns ${getMention(props.headers[1].title)}`)
        "
        :title="$t('Nothing to show for now')"
        :variant="searchText ? EMPTY_STATES_VARIANT.RESULTS : EMPTY_STATES_VARIANT.INDEX"
        data-testid="no-data"
      />
    </template>
    <template #loading> </template>
  </svn-pro-data-table>
</template>

<script setup>
import { toRefs } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useMobileStore } from '@/store/mobile';
import useCampaigns from '@/tools/useCampaigns';
import { useInterviewStore } from '@/store/interview.js';
import { useInterviewUtils } from '@/composables/useInterviewUtils';
import { useCampaignUtils } from '@/composables/useCampaignUtils';
import { EMPTY_STATES_SIZE, EMPTY_STATES_VARIANT, TAB_TYPES } from '@/constants/types';
import useStringTools from '@/tools/useStringTools';

const { uppercaseFirst } = useStringTools();

const props = defineProps({
  headers: { type: Array, required: true },
  tab: {
    type: String,
    default: TAB_TYPES.AS_PARTICIPANT,
    validator: (value) => Object.values(TAB_TYPES).includes(value),
  },
  interviewData: { type: Array, default: () => [] },
  paginationData: { type: Object, default: () => ({}) },
  user: { type: Object, required: true },
  searchText: { type: String, default: '' },
});

const emit = defineEmits(['refetch-interviews']);

const router = useRouter();
const { campaign_icon } = useCampaigns();
const { getAvatar, getText, getLabel } = useInterviewUtils();
const { setInterviewsByUser } = useInterviewStore();

const { isMdScreen } = storeToRefs(useMobileStore());
const { loading } = storeToRefs(useInterviewStore());

const { searchText, tab } = toRefs(props);

const { getTagType, getMention } = useCampaignUtils();

const fetchNewPage = (page) => {
  setInterviewsByUser([]);
  emit('refetch-interviews', {
    userId: props.user.id,
    as: tab?.value,
    text: searchText.value,
    page: page,
  });
};

const goToSpecificInterview = (InterviewId) => {
  if (props.tab === TAB_TYPES.AS_PARTICIPANT || props.tab === TAB_TYPES.AS_PERSON_IN_CHARGE) {
    return goToInterview(InterviewId);
  } else if (props.tab === TAB_TYPES.AS_REVIEWER) {
    return goToInterviewIfFeedback(InterviewId);
  } else {
    return null;
  }
};

const getDynamicTagType = (item) => {
  const status = item?.status;
  return getTagType(status);
};

const getDynamicStatusText = (item) => {
  return item?.status;
};

const goToInterview = (InterviewId) => {
  router.push({ name: 'interview_answer', params: { id: InterviewId } });
};

const goToInterviewIfFeedback = (InterviewId) => {
  router.push({ name: 'feedback_answer', params: { id: InterviewId } });
};
</script>
