<template>
  <trash-warnning
    v-if="isDeleted"
    class="sticky top-[64px] z-[25] bg-surface-light"
    title="This module is in trash."
    @restore="restoreDeletedItems(learnModule.id)"
    @delete-permanently="deleteTargetRef.dialogRef.dialog = true"
  />

  <!-- Header -->
  <svn-pro-card
    :disabled="isDeleted"
    :elevation="0"
    :link="false"
    :rounded="0"
    class="!border-0 w-full h-fit !sticky top-[64px] z-[20]"
    variant="text"
  >
    <svn-pro-top-bar
      :has-primary-button="false"
      :has-secondary-button="false"
      :has-close-button="false"
      :has-left-content="true"
      :has-info-tag="false"
      :icon-buttons-number="4"
      @onBack="handleClose"
      :icon-buttons-array="topbarIcons"
    />
  </svn-pro-card>

  <svn-pro-card
    :disabled="isDeleted"
    :elevation="0"
    :link="false"
    class="!border-0 static w-full h-full"
    variant="text"
  >
    <div class="w-full flex-1 overflow-hidden h-full relative flex bg-surface">
      <div
        class="overflow-y-auto pt-6 px-5 md:!px-5 w-full flex flex-col relative md:!max-w-[672px] lg:!max-w-[770px] xl:!max-w-[752px] gap-6 mx-auto"
      >
        <!-- Main content -->
        <div
          class="w-full flex flex-col relative md:!max-w-[672px] lg:!max-w-[770px] xl:!max-w-[752px] gap-6 mx-auto pb-[140px]"
        >
          <!-- Image -->
          <bkt-image-cover-position
            :coordinates="{
              left: learnModule?.cover_offset_left ?? 0,
              top: learnModule?.cover_offset_top ?? 0,
            }"
            :url="learnModule?.cover_url?.['original'] ? learnModule?.cover_url?.['original'] : ''"
            mode="show"
          />

          <div class="flex flex-col gap-6">
            <!-- Module title -->
            <svn-pro-title h5 medium>
              {{ learnModule?.title }}
            </svn-pro-title>

            <!-- Bunch of infos -->
            <div class="flex flex-col gap-4">
              <!-- Duration -->
              <div class="flex items-center gap-1">
                <div>
                  <Icon
                    class="text-onSurfaceVariant"
                    height="16"
                    icon="mingcute:time-line"
                    width="16"
                  />
                </div>

                <svn-pro-text body-medium color="onSurfaceVariant" regular>
                  {{ $t('Total duration') }}:
                </svn-pro-text>

                <svn-pro-text body-medium bold color="onSurfaceVariant">
                  {{ learnModule?.duration || $t('No duration') }}
                </svn-pro-text>
              </div>

              <!-- Themes -->
              <div
                v-if="learnModule?.themes?.length"
                class="w-full flex items-start content-start gap-2 flex-wrap"
              >
                <svn-pro-info-tag
                  v-for="theme in learnModule?.themes"
                  :key="theme"
                  :text="theme?.name"
                  tag-size="compact"
                  tag-style="outlined"
                  tag-type="info"
                />
              </div>

              <!-- No theme -->
              <svn-pro-info-tag
                v-else
                :text="$t('No theme')"
                tag-size="compact"
                tag-style="outlined"
                tag-type="info"
              />

              <!-- Module acquired -->
              <div
                v-if="learnModule?.submission?.status === 'acquired'"
                class="flex itemx-center gap-1"
              >
                <div>
                  <Icon
                    class="text-success"
                    height="16"
                    icon="mingcute:check-circle-line"
                    width="16"
                  />
                </div>

                <svn-pro-text body-medium color="success" regular>
                  {{ $t('Acquired on') }}
                  {{
                    filters.formatDate(
                      learnModuleSubmissionUpdatedAt
                        ? learnModuleSubmissionUpdatedAt
                        : learnModule?.submission_updated_at,
                      'DD/MM/YYYY',
                    )
                  }}
                </svn-pro-text>
              </div>
            </div>

            <!-- Divider -->
            <svn-pro-divider class="border-opacity-100" color="[#767680]" />

            <!-- Content -->
            <div class="w-full flex flex-col gap-4">
              <!-- Content title -->
              <svn-pro-title h6 medium>
                {{ $t('Content') }}
              </svn-pro-title>

              <!-- Rich text editor -->
              <svn-tiptap
                v-if="editorContentData?.blocks?.length && editorContentId"
                :create-image-url="`/api/v2/editor_contents/${editorContentId}/upload_image`"
                :extension-selection="AllTipTapPlugins"
                :extension-slash-command="AllTipTapPlugins"
                :html-data="editorContentData?.blocks"
                :is-editable="false"
              />
            </div>

            <div class="w-full flex flex-col gap-8">
              <!-- Evaluations -->
              <div
                v-if="
                  learnModule?.has_learn_pieces_approval ||
                  learnModule?.has_learn_pieces_quiz ||
                  learnModule?.has_learn_pieces_face_to_face_evaluation
                "
                class="w-full flex flex-col gap-6"
              >
                <!-- Self evaluation is present -->
                <div
                  v-if="learnModule?.has_learn_pieces_approval"
                  class="w-full flex flex-col gap-8 p-6 border border-borderColor border-opacity-100 rounded-[8px]"
                >
                  <!-- Titles -->
                  <div class="flex flex-col gap-[10px]">
                    <svn-pro-title h5 medium>
                      {{
                        $t(
                          "🙌 You've reached the end! Did you learn all the following learning objectives?",
                        )
                      }}
                    </svn-pro-title>

                    <svn-pro-text-area
                      v-if="learnApprovalInput?.text"
                      v-model="learnApprovalInput.text"
                      :label="null"
                      :readonly="true"
                      :variant="'text'"
                      class="text-onSurfaceVariant text-base font-normal leading-[28px] tracking-[0.15px]"
                    />
                  </div>

                  <div class="flex justify-center md:!justify-end items-center w-full">
                    <svn-pro-dialog-validation
                      :action-one-title="nextModuleId ? $t('Next module') : $t('Go to catalog')"
                      :action-two-title="$t('Cancel')"
                      :content-text="
                        $t('Congratulations, name, keep it up!', {
                          name: firstname,
                        })
                      "
                      :title="$t('Module acquired!')"
                      icon="noto:party-popper"
                      @click-primary-button="openLearnReactionModal('save')"
                      @click-secondary-button="openLearnReactionModal('cancel')"
                      @click-outside="openLearnReactionModal('cancel')"
                    >
                      <template #activator="{ props }">
                        <svn-pro-button
                          :text="
                            learnModule?.submission?.status === 'acquired'
                              ? $t('Update acquisition date')
                              : $t(`Yes, it's all clear to me!`)
                          "
                          class="w-full md:!w-fit"
                          v-bind="props"
                          variant="flat"
                          @click="toggleFullyUnderstood"
                        />
                      </template>
                    </svn-pro-dialog-validation>
                  </div>
                </div>

                <!-- Evaluation Quiz is present -->
                <div
                  v-else-if="learnModule?.has_learn_pieces_quiz"
                  class="w-full flex flex-col gap-8 p-6 border border-borderColor border-opacity-100 rounded-[8px]"
                >
                  <!-- Titles -->
                  <div class="flex flex-col gap-[10px]">
                    <svn-pro-title h5 medium>
                      {{ $t('🙌 You’ve reached the end! Ready for the quiz?') }}
                    </svn-pro-title>

                    <svn-pro-text color="onSurfaceVariant" regular subtitle-large>
                      {{ $t('To complete this module, evaluate yourself with a quiz.') }}
                    </svn-pro-text>
                  </div>

                  <!-- Start the Quiz block -->
                  <div class="flex justify-center md:!justify-end items-center w-full">
                    <svn-pro-button
                      :text="
                        learnModule?.submission_status === 'not_started'
                          ? $t('Start the quiz')
                          : $t('Retake quiz')
                      "
                      variant="flat"
                      @click="
                        router.push({
                          name: 'quiz_edit',
                          params: { id: piecesQuizId },
                          query: {
                            moduleId: learnModule?.id,
                            moduleTitle: learnModule?.title,
                            trainingId: learnTraining?.id,
                          },
                        })
                      "
                    />
                  </div>
                </div>

                <div
                  v-else-if="learnModule?.has_learn_pieces_face_to_face_evaluation"
                  class="w-full flex flex-col gap-8 p-6 border border-borderColor border-opacity-100 rounded-[8px]"
                >
                  <!-- Titles -->
                  <div class="flex flex-col items-start gap-[10px] self-stretch">
                    <!-- Evaluation in progress -->
                    <svn-pro-title
                      v-if="learnModule?.submission?.status === 'in_progress'"
                      :h5="isMdScreen"
                      :h6="!isMdScreen"
                      medium
                    >
                      {{
                        $t(
                          '🙌 You’ve reached the end! Validate your evaluation to complete this module.',
                        )
                      }}
                    </svn-pro-title>

                    <!-- Evaluation acquired -->
                    <svn-pro-title
                      v-else-if="learnModule?.submission?.status === 'acquired'"
                      :h5="isMdScreen"
                      :h6="!isMdScreen"
                      medium
                    >
                      {{ $t('✅ Evaluation done, you have acquired this module!') }}
                    </svn-pro-title>

                    <!-- Notify expert before evaluation -->
                    <svn-pro-title v-else h5 medium>
                      {{
                        $t(
                          '🙌 You’ve reached the end! Please notify your expert before starting the evaluation.',
                        )
                      }}
                    </svn-pro-title>

                    <!-- Evaluation not acquired text -->
                    <svn-pro-text
                      v-if="learnModule?.submission?.status !== 'acquired'"
                      color="onSurfaceVariant"
                      regular
                      subtitle-large
                    >
                      {{
                        $t(
                          'To successfully complete this module, your answers must be reviewed in real time by your expert.',
                        )
                      }}
                    </svn-pro-text>

                    <!-- Evaluation acquired text -->
                    <svn-pro-text v-else color="onSurfaceVariant" regular subtitle-large>
                      {{
                        $t(
                          'This module has been acquired, but you can still restart the evaluation.',
                        )
                      }}
                    </svn-pro-text>
                  </div>
                  <!-- Start Evaluation Block -->
                  <div class="flex justify-end items-center w-full">
                    <svn-pro-button
                      :text="
                        learnModule?.submission?.status === 'not_started' ||
                        Object.keys(learnModule?.submission || {}).length === 0
                          ? $t('Start evaluation!')
                          : learnModule?.submission?.status === 'acquired'
                            ? $t('Restart evaluation!')
                            : $t('Continue evaluation')
                      "
                      variant="flat"
                      @click="navigateToFaceToFaceEdit(learnModule)"
                    />
                  </div>
                </div>

                <!-- Next Or Previous Module -->
                <div
                  v-if="route?.query?.trainingId || route?.query?.playlistId"
                  class="w-full flex items-center justify-end gap-2"
                >
                  <!-- Go to previous module -->
                  <svn-pro-tooltip :text="$t('Previous module')">
                    <template #activator="{ props }">
                      <svn-pro-icon-button
                        :disabled="!prevModuleId"
                        color="onSurfaceVariant"
                        icon="custom:mingcute:arrow-left-line"
                        v-bind="props"
                        variant="outlined"
                        @click="goToPreviousModule"
                      />
                    </template>
                  </svn-pro-tooltip>

                  <!-- Next module -->
                  <svn-pro-tooltip :text="$t('Next module')">
                    <template #activator="{ props }">
                      <svn-pro-icon-button
                        v-if="learnModule?.submission?.status !== 'acquired'"
                        :disabled="!nextModuleId"
                        icon="custom:mingcute:arrow-right-line"
                        v-bind="props"
                        variant="tonal"
                        @click="validation.dialogRef.dialog = true"
                      />

                      <svn-pro-icon-button
                        v-else
                        :disabled="!nextModuleId"
                        icon="custom:mingcute:arrow-right-line"
                        v-bind="props"
                        variant="tonal"
                        @click="goToNextModule"
                      />
                    </template>
                  </svn-pro-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Scroll to top button -->
        <svn-pro-floating-action-button
          :class="drawerLarge ? 'right-[25%]' : 'right-4'"
          :rounded="'lg'"
          class="fixed bottom-4 bg-white transition-all"
          color="primary"
          icon="custom:mingcute:arrow-to-up-line"
          size="small"
          to-top
          variant="tonal"
        />
      </div>

      <!-- Drawer Large -->
      <div
        :class="drawerLarge ? 'w-[344px]' : 'w-0'"
        class="h-full overflow-y-auto flex flex-col transition-all relative"
      >
        <comments-drawer
          :amICreatorOfEntityOrAdmin="amICreatorOfEntityOrAdmin(learnModule?.creator_id)"
          :drawer-large="drawerLarge"
          :is-mobile="false"
          :learnModule="learnModule"
          :learnModulesComments="learnModulesComments"
          :userId="userId"
          @delete-comment="deleteComment($event, false)"
          @delete-comment-in-thread="deleteComment($event, true)"
          @add-comment-reply="addCommentReply"
          @add-comment-thread-reply="addCommentThreadReply"
          @close-drawer="drawerLarge = false"
        />
      </div>
    </div>
  </svn-pro-card>

  <!-- Reactions -->
  <modal-what-did-you-think-of-the-module
    ref="modalModuleReaction"
    :reactions="reactions"
    @update-reaction="onReactionUpdated"
    @click-primary-button="goToNextModuleOrCatalogOrCancel"
    @click-secondary-button="goToNextModuleOrCatalogOrCancel"
    @click-outside="goToNextModuleOrCatalogOrCancel"
    @click-close="goToNextModuleOrCatalogOrCancel"
  >
    <template #activator>
      <div class="hidden opacity-0" />
    </template>
  </modal-what-did-you-think-of-the-module>

  <!-- Comments drawer Mobile -->
  <v-navigation-drawer
    v-model="drawerCommentsMobile"
    :elevation="0"
    :width="!isMdScreen ? drawerWidthMobile : 344"
    disable-resize-watcher
    location="right"
    scrim="transparent"
    temporary
  >
    <comments-drawer
      :amICreatorOfEntityOrAdmin="amICreatorOfEntityOrAdmin(learnModule.creator_id)"
      :drawer-large="drawerCommentsMobile"
      :is-mobile="true"
      :learnModule="learnModule"
      :learnModulesComments="learnModulesComments"
      :userId="userId"
      @delete-comment="deleteComment($event, false)"
      @delete-comment-in-thread="deleteComment($event, true)"
      @add-comment-reply="addCommentReply"
      @add-comment-thread-reply="addCommentThreadReply"
      @close-drawer="drawerCommentsMobile = false"
    />
  </v-navigation-drawer>

  <!-- Dialog delete comment -->
  <svn-pro-dialog-validation
    ref="dialogDeleteComment"
    :action-one-title="$t('Delete')"
    :action-two-title="$t('Cancel')"
    :content-text="$t('This is a permanent action.')"
    :title="$t('Comment will be deleted')"
    icon="noto:warning"
    @click-primary-button="deleteModuleComment"
  >
    <template #activator>
      <div class="hidden opacity-0" />
    </template>
  </svn-pro-dialog-validation>

  <!-- Validation when clicking on next -->
  <svn-pro-dialog-validation
    ref="validation"
    :action-one-title="
      learnModule?.has_learn_pieces_quiz
        ? $t(`Start`)
        : learnModule?.has_learn_pieces_face_to_face_evaluation
          ? $t(`Start`)
          : $t('Confirm')
    "
    :action-two-title="
      learnModule?.has_learn_pieces_quiz
        ? $t(`Do it later`)
        : learnModule?.has_learn_pieces_face_to_face_evaluation
          ? $t(`Do it later`)
          : $t('Not yet')
    "
    :content-text="
      learnModule?.has_learn_pieces_quiz
        ? $t(`To mark this module as “acquired”, you must pass the assestment quiz.`)
        : learnModule?.has_learn_pieces_face_to_face_evaluation
          ? $t(`To mark this module as “acquired”, you must pass the face-to-face evaluation.`)
          : $t('To mark this module as “acquired”, you must confirm that you have understood it.')
    "
    :title="
      learnModule?.has_learn_pieces_quiz
        ? $t(`Start the quiz`)
        : learnModule?.has_learn_pieces_face_to_face_evaluation
          ? $t(`Face-to-face evaluation`)
          : $t('Module will be acquired')
    "
    icon="noto:bell"
    @click-secondary-button="goToNextModule"
    @click-primary-button="
      learnModule?.has_learn_pieces_quiz
        ? openQuiz()
        : learnModule?.has_learn_pieces_face_to_face_evaluation
          ? startFaceToFaceEvaluation()
          : toggleFullyUnderstood()
    "
  >
    <template #activator>
      <div class="hidden opacity-0" />
    </template>
  </svn-pro-dialog-validation>

  <svn-pro-dialog-validation
    ref="deleteTargetRef"
    :action-one-title="$t('Delete')"
    :action-two-title="$t('Cancel')"
    :content-text="$t('This is a permanent action.')"
    :title="$t(`Module will be deleted`)"
    icon="noto:warning"
    @click-primary-button="deletePermanentlyDeletedItems()"
    @click-secondary-button="deleteTargetRef.dialogRef.dialog = false"
  >
    <template #activator>
      <div class="hidden" />
    </template>
  </svn-pro-dialog-validation>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import CommentsDrawer from '@/components/trainingApp/CommentsDrawer.vue';
import { computed, onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue';
import i18n from '@/plugins/i18n.js';
import { useLearnModuleStore } from '@/store/learn-module';
import { useRoute, useRouter } from 'vue-router';
import { useSnackbar } from '@/store/snackbar';
import { storeToRefs } from 'pinia';
import { useToastStore } from '@/store/toast.js';
import { useMobileStore } from '@/store/mobile';
import { useActionCable } from '@/store/cable.js';
import { useUserStore } from '@/store/user.js';
import { debounce } from 'lodash';
import { useLearnPlaylistStore } from '@/store/learn-playlist';
import { useLearnTrainingStore } from '@/store/learn-trainings.js';
import filters from '@/tools/filters';
import TrashWarnning from '@/components/TrashWarnning.vue';
import { LearnInputType } from '@/constants/types';
import BktImageCoverPosition from '@/components/image/bkt-image-cover-position.vue';
import { AllTipTapPlugins } from 'svn-ui-library/extensions';
import ModalWhatDidYouThinkOfTheModule from '@/components/BktPopUp/Modals/learn/ModalWhatDidYouThinkOfTheModule';
import { reactionAPi } from '../../../../../apis/reaction.api';

const { addToast } = useToastStore();
const { cable } = storeToRefs(useActionCable());
const { id: userId, firstname } = storeToRefs(useUserStore());
const { fetchPlaylist } = useLearnPlaylistStore();
const { fetchTraining } = useLearnTrainingStore();
const { amICreatorOfEntityOrAdmin } = useUserStore();
const {
  fetchModule,
  addReaction,
  deleteReaction,
  getSubmissionFaceToFace,
  fetchModuleComments,
  toggleFavorite,
  approvalSubmission,
  updateApprovalSubmissionDate,
  fetchInputs,
  fetchInputsFaceToFace,
  createSubmissionFaceToFace,
  resetStates,
  getEditorContent,
  restoreModule,
  deleteModulePermanently,
} = useLearnModuleStore();

const {
  learnModule,
  learnModulesComments,
  inputs,
  inputsFaceToFace,
  piecesQuizId,
  piecesFaceToFaceEvalId,
  learnResponsesCheckboxes,
  submission,
  editorContentData,
  editorContentId,
  learnModuleSubmissionUpdatedAt,
  learnApprovalInput,
} = storeToRefs(useLearnModuleStore());
const { learnTraining } = storeToRefs(useLearnTrainingStore());
const { learnPlaylist } = storeToRefs(useLearnPlaylistStore());
const { isMdScreen, isLgScreen } = storeToRefs(useMobileStore());

const validation = ref(null);
const nextModuleId = ref(null);
const prevModuleId = ref(null);
const drawerWidthMobile = ref(null);
const drawerCommentsMobile = ref(false);
const alignTop = ref(false);
const editorContentChannel = ref(null);
const inputChannel = ref(null);
const dialogDeleteComment = ref(false);
const commentToDelete = ref(null);
const isDeleted = ref(false);
const modalModuleReaction = ref(null);
const faceToFaceEvaluationModal = ref(null);
const finalAction = ref(null);
const drawerLarge = ref(false);
const commentIsInThread = ref(false);
const deleteTargetRef = ref(false);

const topbarIcons = computed(() => {
  const canEdit = amICreatorOfEntityOrAdmin(learnModule.value?.creator_id)

  return [
    ...(canEdit ? [{
      icon: 'custom:mingcute:edit-2-line',
      onClick: () => goToModuleEdit(),
    }] : []),
    {
      icon: 'custom:mingcute:chat-4-line',
      onClick: () => toggleComments(),
    },
    {
      icon: learnModule?.value.connected_user_favorited ? 'custom:mingcute:heart-fill' : 'custom:mingcute:heart-line',
      onClick: () => toggleModuleFavorite(),
      color: learnModule?.value.connected_user_favorited ? 'primary' : 'onSurfaceVariant',
    },
    {
      icon: 'custom:mingcute:link-line',
      onClick: () => copyLink(),
    },
  ]
})

const reactions = computed(() => {
  return [
    {
      value: 'like',
      icon: 'https://lottie.host/?file=1d730887-ff07-4bcb-916a-1c47a1fc2191/tBJxPeERxp.json',
      text: i18n.global.t('I like it'),
      active: learnModule?.value?.user_reactions?.find((reaction) => reaction?.type === 'Reaction::Like')
        ?.selected,
    },
    {
      value: 'recommend',
      icon: 'https://lottie.host/?file=f503d535-88db-4e36-b0b3-a061d09424f7/XWharmF7Hb.json',
      text: i18n.global.t('I recommend'),
      active: learnModule?.value?.user_reactions?.find((reaction) => reaction?.type === 'Reaction::Recommend')
        ?.selected,
    },
  ];
});

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();

onMounted(async () => {
  if (route.query.drawer_opened === 'true') {
    toggleComments();
  }

  await mountedMethods();
  const action = route.query.returnAction;
  if (action === 'module-validated') {
    modalModuleReaction.value.moduleReactionsModal.dialog = true;
  } else if (action === 'module-saved') {
    snackbar.closeSnackBar();

    setTimeout(() => {
      snackbar
        .setBgColor('onSurface')
        .setCustomClass(window.innerWidth <= 800 ? 'mb-[72px]' : 'mb-[24px]')
        .setMsg('Answers saved. You can come back later to finish the evaluation.')
        .setMultiLine(window.innerWidth <= 800)
        .displaySnackBar();
    }, 100);
  }
  window.addEventListener('resize', resizeFunction);
});

onUnmounted(() => {
  window.removeEventListener('resize', resizeFunction);
});

const resizeFunction = () => {
  if (window.innerWidth < 1440 && drawerLarge?.value) {
    drawerLarge.value = false;
    drawerCommentsMobile.value = true;
  }
  if (window.innerWidth >= 1440 && drawerCommentsMobile?.value) {
    drawerCommentsMobile.value = false;
    drawerLarge.value = true;
  }
};

const openQuiz = async () => {
  await router.push({
    name: 'quiz_edit',
    params: { id: piecesQuizId.value },
    query: {
      moduleId: learnModule.value?.id,
      moduleTitle: learnModule.value?.title,
      trainingId: learnTraining.value?.id,
    },
  });
};

const navigateToFaceToFaceEdit = (learnModule) => {
  router.push({
    name: 'face_to_face_edit',
    params: {
      id: piecesFaceToFaceEvalId.value,
    },
    query: {
      moduleTitle: learnModule.title,
      moduleId: learnModule.id,
      trainingId: learnTraining.value?.id,
      moduleEditor: learnModule.editor_content_id,
    },
  });
};

const startFaceToFaceEvaluation = debounce(async () => {
  faceToFaceEvaluationModal.value.modalFaceToFace.dialog = true;

  try {
    if (submission?.value?.id) {
      await getSubmissionFaceToFace(piecesFaceToFaceEvalId.value);
    } else {
      await createSubmissionFaceToFace(piecesFaceToFaceEvalId.value);
    }
  } catch (error) {
    console.log(error);
  }
}, 300);

const openLearnReactionModal = (action) => {
  modalModuleReaction.value.moduleReactionsModal.dialog = true;

  finalAction.value = action;
};

const addCommentReply = debounce(async (text) => {
  try {
    await addReaction(learnModule?.value?.id, '', text);
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error adding comment')
      .displaySnackBar();
  }
}, 300);

const addCommentThreadReply = debounce(async (data) => {
  try {
    await addReaction(data?.messageable_id, data?.reaction_type, data?.comment, data?.comment_id);
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error adding comment in thread.')
      .displaySnackBar();
  }
}, 300);

const deleteComment = (comment, thread = false) => {
  commentIsInThread.value = thread;
  commentToDelete.value = comment;
  dialogDeleteComment.value.dialogRef.dialog = true;
};

const deleteModuleComment = debounce(async () => {
  try {
    await deleteReaction(
      learnModule?.value?.id,
      commentToDelete.value?.id,
      commentToDelete.value?.parent_id,
    );
    dialogDeleteComment.value.dialogRef.dialog = false;

    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-[72px]' : 'mb-4')
      .setMsg('Comment has been deleted successfully.')
      .displaySnackBar();
  } catch (error) {
    dialogDeleteComment.value.dialogRef.dialog = false;

    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error deleting comment')
      .displaySnackBar();
  }
  commentIsInThread.value = false;
  commentToDelete.value = null;
}, 300);

const toggleFullyUnderstood = async () => {
  if (learnModule?.value?.submission && Object.keys(learnModule?.value?.submission).length) {
    try {
      await updateApprovalSubmissionDate();
      snackbar
        .setBgColor('onSurface')
        .setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]')
        .setMsg('Acquisition date has been updated')
        .displaySnackBar();
    } catch (error) {
      snackbar
        .setBgColor('onSurface')
        .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
        .setMsg('Error updating acquisition date')
        .displaySnackBar();
    }
  } else {
    await approvalSubmission();
  }
};

const onReactionUpdated = async (reactionType) => {
  try {
    const ret = await reactionAPi.put(route?.params?.id, reactionType);
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error toggling reaction')
      .displaySnackBar();
  }
};

const toggleComments = () => {
  if (isLgScreen?.value) {
    drawerLarge.value = !drawerLarge.value;
  } else {
    drawerCommentsMobile.value = !drawerCommentsMobile.value;
  }
};

const toggleModuleFavorite = debounce(async () => {
  try {
    await toggleFavorite(route?.params?.id, learnModule?.value?.status);
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg(
        learnModule?.value?.connected_user_favorited
          ? 'Module added to favorites.'
          : 'Module removed from favorites.',
      )
      .displaySnackBar();
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error toggling favorite')
      .displaySnackBar();
  }
}, 300);

const copyLink = debounce(() => {
  navigator.clipboard.writeText(window.location.href);

  snackbar
    .setBgColor('onSurface')
    .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
    .setMsg('Link copied to clipboard.')
    .displaySnackBar();
}, 300);

const goToModuleEdit = () => {
  router.push({ name: 'module_edit', params: { id: route?.params?.id } });
};

const handleClose = () => {
  router.back();
};

const goToCatalog = () => {
  router.push({ name: 'catalog', query: { tab: 'module' } });
};

window.onscroll = () => {
  scrollFunction();
};

const scrollFunction = () => {
  if (document.documentElement.scrollTop >= 300) {
    alignTop.value = true;
  } else {
    alignTop.value = false;
  }
};

const restoreDeletedItems = async (id) => {
  try {
    await restoreModule(id);
    snackbar.setBgColor('positiveGreen');
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg(`Module has been restored successfully.`)
      .displaySnackBar();

    isDeleted.value = false;
  } catch (e) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error restoring a module')
      .displaySnackBar();
  }
};

const deletePermanentlyDeletedItems = async () => {
  try {
    await deleteModulePermanently(learnModule?.value?.id, false);
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Module has been deleted successfully.')
      .displaySnackBar();

    router.push({ name: 'catalog', query: { tab: 'module' } });
  } catch (e) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error deleting module.')
      .displaySnackBar();
  }
};

const mountedMethods = async () => {
  await resetStates();
  // window.addEventListener("scroll", alignFunc);

  drawerWidthMobile.value = window?.innerWidth;

  try {
    await fetchModule(route?.params?.id);
    updateEditorContent();

    if (learnModule?.value?.has_learn_pieces_quiz) {
      await fetchInputs(learnModule.value?.learn_pieces_quiz?.id);
      await updateInputQuestion();
    }

    if (learnModule?.value?.has_learn_pieces_face_to_face_evaluation) {
      await fetchInputsFaceToFace(learnModule.value?.learn_pieces_face_to_face_evaluation?.id);
      await updateInputQuestion();
    }

    if (learnModule?.value?.editor_content_id) {
      await getEditorContent(learnModule?.value?.editor_content_id);
    }

    // On vient sur le module depuis un training
    if (route?.query?.trainingId) {
      await fetchTraining(route?.query?.trainingId);

      if (learnTraining.value.modules_and_playlist.length) {
        const moduleIds = learnTraining.value.modules_and_playlist
          .map((content) => {
            if (content.content_type === 'Learn::Playlist') {
              return content.learn_modules.map((playlist_content) => {
                return playlist_content.id;
              });
            } else {
              return content.id;
            }
          })
          .flat(1);

        const moduleIdsClean = moduleIds.filter(Boolean);
        const currentIndex = moduleIdsClean.indexOf(parseInt(route?.params.id));
        nextModuleId.value = moduleIdsClean[currentIndex + 1];
        prevModuleId.value = moduleIdsClean[currentIndex - 1];
      }
    }

    // On vient sur le module depuis une playlist
    if (route?.query?.playlistId) {
      await fetchPlaylist(route?.query?.playlistId);

      if (learnPlaylist.value.learn_modules.length) {
        const moduleIds = learnPlaylist.value.learn_modules.map((mod) => mod?.id);

        const currentIndex = moduleIds.indexOf(parseInt(route?.params.id));
        nextModuleId.value = moduleIds[currentIndex + 1];
        prevModuleId.value = moduleIds[currentIndex - 1];
      }
    }

    isDeleted.value = learnModule.value.status === 'trash';
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error fetching module.')
      .displaySnackBar();
  }

  try {
    await fetchModuleComments(route?.params?.id);
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error fetching module reactions.')
      .displaySnackBar();
  }
};

const updateInputQuestion = debounce(async () => {
  const subscribeOptions = {
    channel: 'Learn::InputChannel',
    piece_id:
      learnModule?.value?.submission_type === 'Learn::Pieces::Quiz'
        ? piecesQuizId.value
        : piecesFaceToFaceEvalId.value,
  };

  inputChannel.value = cable.value.subscriptions.create(subscribeOptions, {
    connected: function () {
      // Called when the subscription is ready for use on the server
    },

    disconnected: function () {
      // Called when the subscription has been terminated by the server
    },

    received: function (data) {
      if (data.status === 'update') {
        const index = inputs.value.findIndex((x) => x.id === data.entity.id);
        const indexFaceToFace = inputsFaceToFace.value.findIndex((x) => x.id === data.entity.id);

        if (data.entity.type === LearnInputType.CHECKBOX && index >= 0) {
          inputs.value[index].title = data.entity.title;
          inputs.value[index].proposals = data.entity.proposals;
          inputs.value[index].position = data.entity.position;
        } else if (data.entity.type === LearnInputType.OPEN && indexFaceToFace >= 0) {
          inputsFaceToFace.value[indexFaceToFace].title = data.entity.title;
          inputsFaceToFace.value[indexFaceToFace].description = data.entity.description;
          inputsFaceToFace.value[indexFaceToFace].position = data.entity.position;
        } else {
          addToast(
            'info',
            i18n.global.t(`This module has just been updated !`),
            i18n.global.t(
              `The lastest version of this content will be visible if you reload this page.`,
            ),
            false,
            {
              name: i18n.global.t(`Reload this page`),
              link: '/learns/module/' + learnModule.value.id + '/show',
            },
          );
        }
      }
    },

    update: async function () {},
  });
}, 300);

const updateEditorContent = debounce(async () => {
  const subscribeOptions = {
    channel: 'EditorContentChannel',
    id: learnModule.value.editor_content_id,
  };

  editorContentChannel.value = cable.value.subscriptions.create(subscribeOptions, {
    connected: function () {
      // Called when the subscription is ready for use on the server
    },

    disconnected: function () {
      // Called when the subscription has been terminated by the server
    },

    received: function (data) {
      if (data.status === 'update') {
        addToast(
          'info',
          i18n.global.t(`This module has just been updated !`),
          i18n.global.t(
            `The lastest version of this content will be visible if you reload this page.`,
          ),
          false,
          {
            name: i18n.global.t(`Reload this page`),
            link: '/learns/module/' + learnModule.value.id + '/show',
          },
        );
      } else {
        if (data?.entity?.blocks?.length) {
          editorContentData.value.blocks = data.entity.blocks;
        } else {
          editorContentData.value.blocks = emptyBlocks.value;
        }
      }
    },

    update: async function () {},
  });
}, 300);

const goToNextModuleOrCatalogOrCancel = () => {
  modalModuleReaction.value.moduleReactionsModal.dialog = false;
  console.log('GOTO_NEXT_MODULE');

  if (route.query.redirectCatalog) {
    goToCatalog();
  }
  if (route.query.nextModuleId) {
    goToNextModule();
  }
  if (learnModule?.value?.has_learn_pieces_approval) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Acquisition date has been updated.')
      .displaySnackBar();
  }

  if (finalAction?.value === 'save') {
    modalModuleReaction.value.moduleReactionsModal.dialog = false;

    if (nextModuleId?.value) {
      goToNextModule();
    } else {
      goToCatalog();
    }
  } else if (finalAction?.value === 'push-training-show') {
    router.push({
      name: 'training_show',
      params: { id: route?.query?.trainingId },
    });
  } else if (finalAction?.value === 'push-training-show-and-recall') {
    router
      .push({
        name: 'module_show',
        params: { id: nextModuleId?.value },
        query: { trainingId: route?.query?.trainingId },
      })
      .then(() => {
        mountedMethods();
      });
  } else if (finalAction?.value === 'push-catalog') {
    router.push({ name: 'catalog', query: { tab: 'module' } });
  } else if (finalAction?.value === 'cancel') {
    modalModuleReaction.value.moduleReactionsModal.dialog = false;
  }
};

const goToNextModule = () => {
  if (nextModuleId?.value) {
    if (route?.query?.trainingId) {
      router
        .push({
          name: 'module_show',
          params: { id: nextModuleId?.value },
          query: { trainingId: route?.query?.trainingId },
        })
        .then(async () => {
          await mountedMethods();
        });
    } else if (route?.query.playlistId) {
      router
        .push({
          name: 'module_show',
          params: { id: nextModuleId?.value },
          query: { playlistId: route?.query?.playlistId },
        })
        .then(async () => {
          await mountedMethods();
        });
    }
  }
};

const goToPreviousModule = () => {
  if (prevModuleId?.value) {
    if (route?.query?.trainingId) {
      router
        .push({
          name: 'module_show',
          params: { id: prevModuleId?.value },
          query: { trainingId: route?.query?.trainingId },
        })
        .then(async () => {
          await mountedMethods();
        });
    } else if (route?.query.playlistId) {
      router
        .push({
          name: 'module_show',
          params: { id: prevModuleId?.value },
          query: { playlistId: route?.query?.playlistId },
        })
        .then(async () => {
          await mountedMethods();
        });
    }
  }
};

onBeforeUnmount(() => {
  inputChannel?.value?.unsubscribe();
  editorContentChannel?.value?.unsubscribe();
});
</script>
