<template>
  <div class="w-full flex flex-col gap-6">
    <div class="w-full flex flex-col gap-4">
      <div class="flex flex-col gap-2">
        <svn-pro-text subtitle-large medium>
          {{ $t("Choose how you want to display template(s)") }}
        </svn-pro-text>
  
        <svn-pro-text v-if="campaignDraft?.campaign_type === 'Survey'" subtitle-medium medium color="onSurfaceVariant">
          {{ $t("All participants will have this template for the survey") }}
        </svn-pro-text>
      </div>
  
      <v-item-group
        v-if="campaignDraft.campaign_type !== 'Survey'"
        :model-value="templateType"
        mandatory
        @update:model-value="updateTemplateType"
      >
        <div class="w-full flex flex-col md:!grid md:!grid-cols-2 gap-4">
          <svn-pro-extended-radio-button
            v-for="(item, idx) in templatesSelectionMethodList"
            :key="item?.value"
            :value="item?.value"
            :icon="item?.icon"
            :title="item?.title"
            :subtitle="item?.subtitle"
          />
        </div>
      </v-item-group>
  
      <templates-single v-if="templateType === 'single'" :error="templateError" />

      <templates-multiple v-if="templateType === 'multiple'" :errorDefault="templateError" :error="multipleTemplateError" />
    </div>

    <!-- Previous & Next button button -->
    <div class="w-full flex justify-end items-center gap-2">
      <svn-pro-button
        variant="outlined"
        :text="$t('Previous')"
        @click="previous"
      />

      <svn-pro-button
        variant="flat"
        :text="$t('Next')"
        :loading="loading"
        prepend-icon="custom:mingcute:arrow-right-line"
        @click="next"
      />
    </div>
  </div>
  
  <svn-pro-dialog
    v-model="dialog"
    :title="$t('Some tags have no templates assigned')"
    :content-text="$t('If you continue, default template will be assigned to those tags.')"
    :action-one-title="$t('Continue')"
    :action-two-title="$t('Assign tags')"
    @click-primary-button="updateCampaignDraftAndSnackbar"
    @click-secondary-button="dialog = false"
  >
    <template #activator>
      {{ null }}
    </template>
  </svn-pro-dialog>
</template>

<script setup>
import { debounce } from "lodash";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import { useSnackbar } from "@/store/snackbar.js";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import TemplatesSingle from "@/components/interviewApp/campaignDraft/templates/TemplatesSingle.vue";
import TemplatesMultiple from "@/components/interviewApp/campaignDraft/templates/TemplatesMultiple.vue";

const { t } = useI18n();
const snackbar = useSnackbar();
const { fetchParticipantTags, updateCampaignDraft } = useCampaignDraftStore();

const { campaignDraft, participantTags } = storeToRefs(useCampaignDraftStore());

const dialog = ref(false);
const loading = ref(false);
const templateError = ref(false);
const multipleTemplateError = ref(false);
const templateType = ref("single");
const templatesSelectionMethodList = ref([
  {
    icon: "noto:clipboard",
    title: t("Single template"),
    subtitle: t("Every participants have the same template."),
    value: "single",
    component: "templates-single",
  },
  {
    icon: "noto:bookmark-tabs",
    title: t("Multiple templates"),
    subtitle: t("Define dynamic templates depending on participants informations"),
    value: "multiple",
    component: "templates-multiple",
  },
]);

const emit = defineEmits(['previous', 'next'])

onMounted(() => {
  if (campaignDraft?.value?.templates_selection_method) {
    templateType.value = campaignDraft?.value?.templates_selection_method
  }
  if (campaignDraft?.value?.multi_templates_tag_category?.id) {
    fetchParticipantTags(campaignDraft?.value?.multi_templates_tag_category?.id);
  }
})

const updateTemplateType = (type) => {
  campaignDraft.value.templates_selection_method = templatesSelectionMethodList?.value?.find(el => el?.value === type)?.value
  templateType.value = campaignDraft?.value?.templates_selection_method
}

const previous = debounce(() => {
  emit("previous");
});

const next = async() => {
  loading.value = true
  const tagsFilled = campaignDraft?.value?.multi_templates_tags_ids?.filter((item) => item?.form_id);

  if (campaignDraft?.value?.templates_selection_method === 'single') {
    if (campaignDraft?.value?.default_template?.id) {
      await updateCampaignDraftAndSnackbar()
    } else {
      templateError.value = true
      snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("Please fill all required fields (*).").displaySnackBar()
    }
  } else if (campaignDraft?.value?.templates_selection_method === "multiple") {
    if (campaignDraft?.value?.multi_templates_tag_category?.id && campaignDraft?.value?.default_template?.id) {
      if (participantTags?.value?.length !== tagsFilled?.length) {
        dialog.value = true
      } else {
        await updateCampaignDraftAndSnackbar()
      }
    } else {
      if (!campaignDraft?.value?.multi_templates_tag_category?.id) {
        multipleTemplateError.value = true
      }
      if (!campaignDraft?.value?.default_template?.id) {
        templateError.value = true
      }
      snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("Please fill all required fields (*).").displaySnackBar()
    }
  }

  loading.value = false;
}

const updateCampaignDraftAndSnackbar = async() => {
  try {
    await updateCampaignDraft({
      default_template_id: campaignDraft?.value?.default_template?.id,
      multi_templates_tags_ids: campaignDraft?.value?.multi_templates_tags_ids,
      templates_selection_method: campaignDraft?.value?.templates_selection_method,
      multi_templates_tag_category_id: campaignDraft?.value?.multi_templates_tag_category?.id,
    });
    emit("next");
  } catch (error) {
    snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("An error occured, please try again").displaySnackBar()
  }
}

watch(campaignDraft?.value, (newValue, oldValue) => {
  if (newValue.default_template?.id && templateError?.value) {
    templateError.value = false
  }
  if (newValue.multi_templates_tag_category?.id && multipleTemplateError?.value) {
    multipleTemplateError.value = false
  }
})
</script>