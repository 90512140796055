<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-2">
      <svn-pro-text subtitle-large medium>
        {{ $t("Multiple templates - Category") }}
      </svn-pro-text>

      <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
        {{ $t("Choose a category to assign different templates to this campaign.") }}
      </svn-pro-text>
    </div>

    <!-- Select a category -->
    <div class="w-full flex items-center gap-4">
      <svn-pro-select
        v-model="campaignDraft.multi_templates_tag_category"
        :items="tagCategories"
        :label="$t('Select a category*')"
        :return-object="true"
        item-title="name"
        item-value="id"
        :error="error"
        class="flex-1 sm:!w-1/2"
      >
        <template #selection="{ item }">
          <div class="w-full truncate">
            {{ item?.title }}
          </div>
        </template>
      </svn-pro-select>

      <v-spacer class="hidden sm:!flex" />
    </div>

    <div class="flex flex-col gap-2">
      <svn-pro-text subtitle-large medium>
        {{ $t("Multiple templates - Category") }}
      </svn-pro-text>

      <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
        {{ $t("Select a template for each tag. You also have to choose a default template for participants not assigned to either of these tags.") }}
      </svn-pro-text>
    </div>

    <div
      v-if="campaignDraft?.multi_templates_tag_category?.id"
      class="flex flex-col gap-4"
      data-testid="select-templates-container"
    >
      <!-- Default template -->
      <div class="w-full flex flex-col gap-4">
        <div class="w-full flex flex-col gap-4 md:!px-2">
          <svn-pro-text subtitle-large medium class="whitespace-nowrap w-full">
            {{ $t('Default template') }}
          </svn-pro-text>

          <templates-single :error="errorDefault" :full-width="true" />
        </div>

        <svn-pro-divider color="[#767680]" class="w-full" />
      </div>

      <!-- Other templates -->
      <div
        v-for="(tag, idx) in participantTags"
        :key="idx"
        class="flex flex-col gap-4 items-center"
      >
        <div class="w-full flex flex-col gap-4 md:!px-2">
          <svn-pro-text subtitle-large medium class="break-all line-clamp-1 md:!flex-1">
            {{ tag?.tag_name }}
          </svn-pro-text>

          <div class="w-full flex items-center gap-2">
            <svn-pro-autocomplete
              :model-value="fetchInterviewFormId(tag?.id)"
              :label="$t('Select a template')"
              :items="interviewForms"
              class="flex-1"
              item-title="title"
              item-value="id"
              :infiniteScrollLoading="infiniteScrollLoading"
              @intersect="loadMoreData"
              @update:model-value="updateMultiTemplatesTagsIds({
                tag_id: tag?.id,
                form_id: $event,
              })"
            />

            <svn-pro-tooltip :text="$t('Preview')">
              <template #activator="{ props }">
                <svn-pro-icon-button
                  v-bind="props"
                  :disabled="!campaignDraft?.multi_templates_tags_ids.find((item) => item?.tag_id === tag?.id)?.form_id"
                  icon="custom:mingcute:eye-2-line"
                  color="onSurfaceVariant"
                  variant="text"
                  @click="openInterviewFormPreview(tag?.id)"
                />
              </template>
            </svn-pro-tooltip>
          </div>
        </div>

        <svn-pro-divider v-if="idx !== participantTags?.length -1" color="[#767680]" class="w-full" />
      </div>
    </div>

    <v-alert
      v-else
      :text="$t('Please choose a category first')"
      icon="custom:noto-warning"
      rounded="0"
      class="!rounded-[12px]"
    />
  </div>

  <interview-template-preview
    v-show="!!interviewForm"
    :attach="true"
    ref="interviewTemplatePreviewRef"
    :is-deleted="interviewForm?.status === 'trashed'"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import { useCompanyStore } from "@/store/company.js";
import { interviewFormApi } from '@/apis/interview_form.api';
import { useInterviewFormStore } from "@/store/interview-form.js";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import TemplatesSingle from "@/components/interviewApp/campaignDraft/templates/TemplatesSingle.vue";
import InterviewTemplatePreview from "@/components/interviewApp/template/Show/InterviewTemplatePreview.vue";

const props = defineProps({
  error: { type: Boolean, default: false },
  errorDefault: { type: Boolean, default: false },
})

const { fetchParticipantTags } = useCampaignDraftStore();
const { fetchInterviewForm } = useInterviewFormStore();

const { tagCategories } = storeToRefs(useCompanyStore());
const { interviewForm } = storeToRefs(useInterviewFormStore());
const { campaignDraft, participantTags } = storeToRefs(useCampaignDraftStore());

const pagination = ref({});
const interviewForms = ref([]);
const loadingInterviewForms = ref(true);
const infiniteScrollLoading = ref(false);
const interviewTemplatePreviewRef = ref(null);

onMounted(async () => {
  const data = await fetchInterviewForms(1)
  interviewForms.value = data?.interview_app_forms
  pagination.value = data?.meta?.pagination
  loadingInterviewForms.value = false;
});

const fetchInterviewForms = async(page = 1) => {
  let formType = ''
  if (campaignDraft?.value?.campaign_type == 'Survey') {
    formType = 'InterviewApp::Forms::Templates::Survey'
  } else if (campaignDraft?.value?.campaign_type == 'Feedback') {
    formType = 'InterviewApp::Forms::Templates::Feedback'
  } else {
    formType = 'InterviewApp::Forms::Templates::OneToOne'
  }
  return await interviewFormApi.fetch(formType, page);
}

const loadMoreData = async() => {
  if (pagination.value?.current_page < pagination.value?.total_pages) {
    infiniteScrollLoading.value = true
    pagination.value.current_page = pagination.value.current_page + 1
    const data = await fetchInterviewForms(pagination.value.current_page)
    interviewForms.value = [...interviewForms.value, ...data.interview_app_forms]
    pagination.value = data.meta.pagination
    infiniteScrollLoading.value = false;
  }
}

const updateMultiTemplatesTagsIds = (entry) => {
  const idx = campaignDraft?.value?.multi_templates_tags_ids.findIndex((item) => item?.tag_id === entry?.tag_id);

  if (idx !== -1) {
    campaignDraft.value.multi_templates_tags_ids[idx] = entry;
  } else {
    campaignDraft.value.multi_templates_tags_ids.push(entry);
  }
};

const fetchInterviewFormId = (tagId) => {
  const match = campaignDraft?.value?.multi_templates_tags_ids.find((item) => item?.tag_id === tagId);

  return match ? match?.form_id : null;
};

const openInterviewFormPreview = async (tagId) => {
  const match = campaignDraft?.value?.multi_templates_tags_ids.find((item) => item?.tag_id === tagId);

  if (interviewForm?.value?.id !== match?.form_id) {
    await fetchInterviewForm(match?.form_id);
  }

  if (interviewTemplatePreviewRef.value) {
    interviewTemplatePreviewRef.value.dialog = true
  }
}

watch(() => campaignDraft.value?.multi_templates_tag_category?.id, (newValue, oldValue) => {
  if (oldValue !== newValue) {
    campaignDraft.value.multi_templates_tags_ids = []
    fetchParticipantTags(newValue);
  }
})
</script>