<template>
  <v-dialog
    v-model="dialog"
    :width="isMobile || fullscreen ? undefined : 'auto'"
    :fullscreen="isMobile || fullscreen"
    :transition="transition"
    :persistent="persistent"
  >
    <template #activator="{ props }">
      <slot name="button-div">
        <div
          v-bind="props"
          class="w-full"
        >
          <slot
            name="button"
          >
            <v-btn
              class="normal-case"
              :variant="buttonVariant"
              :color="buttonColor"
            >
              <slot name="button-text">
                {{ buttonText }}
              </slot>
            </v-btn>
          </slot>
        </div>
      </slot>
    </template>

    <slot name="modal">
      <div
        class="bg-white w-full h-full flex flex-col overflow-y-auto"
        :class="
          [
            height ?
              width ? `sm:h-[${height}px] sm:w-[${width}px]` : isMobile || fullscreen ? `sm:h-[${height}px]` : `sm:h-[${height}px] sm:w-[600px]` :
              width ? `sm:w-[${width}px]` : isMobile || fullscreen ? null : `sm:w-[600px]`,
            isMobile || fullscreen ? null : '!rounded-xl'
          ]
        "
      >
        <!--+ isMobile || fullscreen ? null : '!rounded-xl'-->
        <slot
          name="header"
          :close="closeDialog"
        >
          <!-- Two line header -->
          <div
            v-if="twoLine"
            class="w-full h-[96px] flex flex-col justify-center items-center
              border-b border-middle-grey px-4 py-2"
            :class="prependIcon || prependText || titleCenter ? 'justify-center' : null"
          >
            <div class="w-full flex flex-col gap-2 sm:hidden">
              <div class="flex justify-between">
                <slot name="prepend-header-icon">
                  <div
                    v-if="prependIcon || prependText"
                    class="flex cursor-pointer text-dark-grey hover:text-fake-black gap-1"
                    @click="$emit('prependClick')"
                  >
                    <div>
                      <Icon
                        v-if="prependIcon"
                        :icon="prependIcon"
                        class="h-6 w-6 cursor-pointer"
                      />
                    </div>

                    <p class="text-dark-grey hover:text-fake-black">
                      {{ prependText }}
                    </p>
                  </div>
                </slot>

                <slot name="close">
                  <div
                    v-if="displayClose"
                    class="w-full flex justify-end"
                    @click="closeDialogAndAction"
                  >
                    <div>
                      <Icon
                        :icon="closeIcon"
                        class="h-6 w-6 text-dark-grey hover:text-fake-black cursor-pointer"
                      />
                    </div>
                  </div>
                </slot>
              </div>

              <div>
                <slot name="title">
                  <p class="text-center truncate">
                    {{ $t(title) }}
                  </p>
                </slot>
              </div>
            </div>

            <div class="w-full h-[46px] hidden sm:flex relative items-center justify-center">
              <slot name="prepend-header-icon">
                <div
                  v-if="prependIcon || prependText"
                  class="flex cursor-pointer text-dark-grey hover:text-fake-black gap-1"
                  @click="$emit('prependClick')"
                >
                  <div>
                    <Icon
                      v-if="prependIcon"
                      :icon="prependIcon"
                      class="h-6 w-6 cursor-pointer"
                    />
                  </div>

                  <p class="text-dark-grey hover:text-fake-black">
                    {{ prependText }}
                  </p>
                </div>
              </slot>

              <v-spacer />

              <slot name="title">
                <div class="absolute w-fit truncate">
                  {{ $t(title) }}
                </div>
              </slot>

              <slot name="close">
                <div
                  v-if="displayClose"
                  @click="closeDialogAndAction"
                >
                  <div>
                    <Icon
                      :icon="closeIcon"
                      class="h-6 w-6 text-dark-grey hover:text-fake-black cursor-pointer"
                    />
                  </div>
                </div>
              </slot>
            </div>
          </div>

          <!-- Single line header -->
          <div
            v-else
            class="h-[64px] w-full relative border-b border-middle-grey px-4 py-2 flex items-center"
            :class="prependIcon || prependText || titleCenter ? 'justify-center' : null"
          >
            <slot name="prepend-header-icon">
              <div
                v-if="prependIcon || prependText"
                class="flex cursor-pointer text-dark-grey hover:text-fake-black gap-1"
                @click="$emit('prependClick')"
              >
                <div>
                  <Icon
                    v-if="prependIcon"
                    :icon="prependIcon"
                    class="h-6 w-6 cursor-pointer"
                  />
                </div>

                <p class="text-dark-grey hover:text-fake-black">
                  {{ prependText }}
                </p>
              </div>
            </slot>

            <v-spacer />

            <slot name="title">
              <div class="absolute w-fit truncate">
                {{ $t(title) }}
              </div>
            </slot>

            <slot name="close">
              <div
                v-if="displayClose"
                @click="closeDialogAndAction"
              >
                <div>
                  <Icon
                    :icon="closeIcon"
                    class="h-6 w-6 text-dark-grey hover:text-fake-black cursor-pointer"
                  />
                </div>
              </div>
            </slot>
          </div>
        </slot>

        <slot name="body">
          <div class="h-full sm:min-h-[256px] overflow-y-auto">
            <slot name="body-content" />
          </div>
        </slot>

        <!-- Action buttons -->
        <slot
          v-if="actionButtons"
          name="actionButtons"
        >
          <div
            class="w-full gap-3 p-6 bg-white justify-center"
            :class="buttonsClass ? buttonsClass : 'flex'"
          >
            <slot name="secondary-button">
              <v-btn
                v-if="displaySecondaryButton"
                class="text-fake-black normal-case text-xs"
                :color="buttonSecondaryColor"
                :class="secondaryButtonClass ? secondaryButtonClass : 'w-1/2'"
                variant="outlined"
                @click="secondaryButtonClick"
              >
                <slot name="secondary-button-body">
                  {{ $t(secondaryButtonText) }}
                </slot>
              </v-btn>
            </slot>
            <slot name="primary-button">
              <!-- pour le bouton en bas :class="vertical ? displaySecondaryButton ? 'w-1/2' : 'w-full' : displaySecondaryButton ? 'w-1/2' :'w-full sm:w-[170px]'"-->
              <v-btn
                v-if="displayPrimaryButton"
                class="normal-case text-xs"
                :color="buttonPrimaryColor"
                :disabled="primaryButtonDisabled"
                :class="primaryButtonClass ? primaryButtonClass : '!w-1/2'"
                variant="flat"
                @click="primaryButtonClick"
              >
                <slot name="primary-button-body">
                  {{ $t(primaryButtonText) }}
                </slot>
              </v-btn>
            </slot>
          </div>
        </slot>
      </div>
    </slot>
  </v-dialog>
</template>

<script setup>
import { ref } from "vue";
import { Icon } from "@iconify/vue";
import { useMobileStore } from "@/store/mobile";
import {storeToRefs} from "pinia";

const mobileStore = useMobileStore()
const { isMobile } = storeToRefs(mobileStore);

const dialog = ref(false)

const emit = defineEmits(['prependClick', 'clickSecondaryButton', 'clickPrimaryButton', 'close-dialog-and-action'])

const props = defineProps({
  buttonText: {
    type: String,
    default: 'Open Dialog'
  },
  buttonColor: {
    type: String,
    default: 'primary',
  },
  buttonVariant: {
    type: String,
    default: 'plain',
  },
  icon: {
    type: String,
    default: ''
  },
  prependIcon: {
    type: String,
    default: ''
  },
  prependText: {
    type: String,
    default: ''
  },
  displayClose: {
    type: Boolean,
    default: true
  },
  closeIcon: {
    type: String,
    default: 'material-symbols:close'
  },
  title: {
    type: String,
    default: ''
  },
  height: {
    type: Number,
    default: 0
  },
  width: {
    type: Number,
    default: 0
  },
  fullscreen: {
    type: Boolean,
    default: false
  },
  twoLine: {
    type: Boolean,
    default: false
  },
  transition: {
    type: String,
    default: "dialog-bottom-transition"
  },
  titleCenter: {
    type: Boolean,
    default: false
  },
  actionButtons: {
    type: Boolean,
    default: false
  },
  twoLine: {
    type: Boolean,
    default: false
  },
  displaySecondaryButton: {
    type: Boolean,
    default: true,
  },
  buttonPrimaryColor: {
    type: String,
    default: 'primary'
  },
  buttonSecondaryColor: {
    type: String,
    default: 'fakeBlack'
  },
  secondaryButtonText: {
    type: String,
    default: ''
  },
  secondaryButtonClass: {
    type: String,
    default: ''
  },
  buttonsClass: {
    type: String,
    default: ''
  },
  primaryButtonDisabled: {
    type: Boolean,
    default: false,
  },
  primaryButtonClosesModal: {
    type: Boolean,
    default: true,
  },
  secondaryButtonClosesModal: {
    type: Boolean,
    default: true,
  },
  closeButtonClosesModal: {
    type: Boolean,
    default: true,
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  primaryButtonText: {
    type: String,
    default: ''
  },
  primaryButtonClass: {
    type: String,
    default: ''
  },
  displayPrimaryButton: {
    type: Boolean,
    default: true,
  },
  displaySecondaryButton: {
    type: Boolean,
    default: true,
  },
})

const closeDialog = () => {
  dialog.value = false
}

const secondaryButtonClick = () => {
  if (props?.secondaryButtonClosesModal) {
    closeDialog()
    emit('clickSecondaryButton')
  }
  else {
    emit('clickSecondaryButton')
  }
}

const primaryButtonClick = () => {
  if (props?.primaryButtonClosesModal) {
    closeDialog()
    emit('clickPrimaryButton')
  }
  else {
    emit('clickPrimaryButton')
  }
}

const closeDialogAndAction = () => {
  if (props?.closeButtonClosesModal) {
    closeDialog()
    emit('close-dialog-and-action')
  }
  else {
    emit('close-dialog-and-action')
  }
}

defineExpose({
  dialog
})
</script>
