<template>
  <svn-pro-data-table
    :headers="
      set?.length
        ? campaign?.campaign_type === CampaignTypeBackend.FEEDBACK_360
          ? feedback_headers
          : campaign?.campaign_type === CampaignTypeBackend.ONE_TO_ONE
            ? headers
            : campaign?.campaign_type === CampaignTypeBackend.SURVEY
              ? survey_headers
              : []
        : []
    "
    :items="set ?? []"
    :items-per-page="-1"
    :loading="set ? loading : true"
    :pagination-total-pages="pagination?.total_pages"
    item-value="id"
    @update-pagination="customFetchCamp"
  >
    <template #item="{ item, index }">
      <tr
        data-testid="table-row"
        class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12] cursor-pointer"
        @click="openModalInterviewStep(item)"
      >
        <!-- Participants -->
        <td>
          <svn-pro-data-table-cell
            :anonym="campaign?.campaign_type === CampaignTypeBackend.SURVEY ? anonymous : false"
            :avatar="
              campaign?.campaign_type === CampaignTypeBackend.FEEDBACK_360
                ? item?.participant?.avatar?.['50']
                : campaign?.campaign_type === CampaignTypeBackend.ONE_TO_ONE
                  ? item?.employee?.avatar?.['50']
                  : item?.employee?.avatar?.['50']
            "
            :supporting-text="
              campaign?.campaign_type === CampaignTypeBackend.FEEDBACK_360
                ? item?.participant?.job_title || null
                : campaign?.campaign_type === CampaignTypeBackend.ONE_TO_ONE
                  ? item?.employee?.job_title || null
                  : null
            "
            :text="
              campaign?.campaign_type === CampaignTypeBackend.FEEDBACK_360
                ? item?.participant?.fullname
                : campaign?.campaign_type === CampaignTypeBackend.ONE_TO_ONE
                  ? item?.employee?.fullname
                  : anonymous
                    ? `Participant ${index}`
                    : item?.employee?.fullname
            "
            body-large
            cell-type="avatar_text_supporting_text"
            medium
          />
        </td>

        <!-- Job title (SURVEY Only) -->
        <td v-if="campaign?.campaign_type === CampaignTypeBackend.SURVEY">
          <svn-pro-data-table-cell
            :text="item?.employee?.job_title"
            body-large
            cell-type="text"
            regular
          />
        </td>

        <!-- Template title -->
        <td>
          <svn-pro-tooltip :text="item?.title || item?.interview_form?.title" :max-width="460" location="top start" origin="auto" open-delay="400">
            <template #activator="{ props }">
              <div v-bind="props">
                <svn-pro-data-table-cell
                  :text="item?.title || item?.interview_form?.title"
                  body-large
                  cell-type="text"
                  regular
                />
              </div>
            </template>
          </svn-pro-tooltip>
        </td>

        <!-- Person in charge / Interviewer -->
        <td v-if="campaign?.campaign_type !== CampaignTypeBackend.SURVEY">
          <svn-pro-data-table-cell
            :avatar="
              campaign?.campaign_type === CampaignTypeBackend?.FEEDBACK_360
                ? item?.person_in_charge?.avatar?.['50']
                : item?.interviewer?.avatar?.['50']
            "
            :supporting-text="
              campaign?.campaign_type === CampaignTypeBackend?.FEEDBACK_360
                ? item?.person_in_charge?.job_title || null
                : item?.interviewer?.job_title || null
            "
            :text="
              campaign?.campaign_type === CampaignTypeBackend?.FEEDBACK_360
                ? item?.person_in_charge?.fullname
                : item?.interviewer?.fullname
            "
            body-large
            cell-type="avatar_text_supporting_text"
            medium
          />
        </td>

        <!-- Completion / Answers -->
        <td>
          <svn-pro-info-tag
            v-if="campaign?.campaign_type === CampaignTypeBackend?.FEEDBACK_360"
            :tag-type="getTagType(item?.answer_count, item?.all_interview_count)"
            :text="`${item?.answer_count} / ${item?.all_interview_count}`"
            class="w-fit"
            tag-size="compact"
            tag-style="filled"
          />

          <interview-status
            v-else-if="campaign?.campaign_type === CampaignTypeBackend?.ONE_TO_ONE"
            :set-interview="item"
          />

          <campaign-status-chip
            v-else-if="campaign?.campaign_type === CampaignTypeBackend?.SURVEY"
            :status="item?.status"
          />
        </td>

        <!-- Buttons -->
        <td>
          <svn-pro-menu :disabled="isTrashed">
            <template #activator>
              <svn-pro-icon-button
                :disabled="isTrashed"
                color="onSurface"
                icon="custom:mingcute:more-2-line"
                variant="text"
              />
            </template>

            <template #dropdown>
              <!-- View profile -->
              <v-list-item
                v-if="
                  (item?.employee?.company_id === item?.interviewer?.company_id &&
                    campaign?.campaign_type !== CampaignTypeBackend?.SURVEY) ||
                  (campaign?.campaign_type === CampaignTypeBackend?.SURVEY && !anonymous)
                "
                :active="false"
                :to="`/people/user_show/${campaign?.campaign_type === CampaignTypeBackend?.FEEDBACK_360 ? item?.participant?.id : item?.employee?.id}?page=interviews`"
                target="_blank"
                value="see_interviewee_profile"
              >
                {{ $t('See participant profile') }}
              </v-list-item>

              <!-- Manage reviewers -->
              <pop-up-manage-pairs
                v-if="campaign?.campaign_type === CampaignTypeBackend.FEEDBACK_360"
                :campaign-id="campaign?.id"
                :feedback-id="item?.id"
                :feedback-interviews="item?.interviews"
                :participant-id="item?.participant?.id"
              >
                <template #activator="{ props }">
                  <v-list-item :active="false" v-bind="props" value="manage_reviewers">
                    {{ $t('Manage Reviewers') }}
                  </v-list-item>
                </template>
              </pop-up-manage-pairs>

              <!-- Set another interviewer -->
              <pop-up-set-another-interviewer
                v-if="
                  campaign?.campaign_type === CampaignTypeBackend.FEEDBACK_360 ||
                  campaign?.campaign_type === CampaignTypeBackend.ONE_TO_ONE
                "
                :campaign-id="campaign?.id"
                :employee-id="
                  campaign?.campaign_type === CampaignTypeBackend.FEEDBACK_360
                    ? item?.participant?.id
                    : item?.employee?.id
                "
                @refetch-campaigns="fetchCampaign(campaign?.id)"
              >
                <template #activator="{ props }">
                  <v-list-item :active="false" v-bind="props" value="set_person_incharge">
                    {{ $t('Set another person in charge') }}
                  </v-list-item>
                </template>
              </pop-up-set-another-interviewer>

              <!-- Send invitation email -->
              <v-list-item
                :active="false"
                value="send_invitation_email"
                @click="
                  campaign?.campaign_type === CampaignTypeBackend?.FEEDBACK_360
                    ? sendNotif('invite', null, item?.id)
                    : sendNotif('invite', item?.employee?.id, null)
                "
              >
                {{ $t('Send invitation email') }}
              </v-list-item>

              <!-- Send Reminder email -->
              <v-list-item
                :active="false"
                value="send_reminder_email"
                @click="
                  campaign?.campaign_type === CampaignTypeBackend?.FEEDBACK_360
                    ? sendNotif('remind', null, item?.id)
                    : sendNotif('remind', item?.employee?.id, null)
                "
              >
                {{ $t('Send reminder email') }}
              </v-list-item>

              <!-- Schedule a meeting -->
              <pop-up-shift-cross-interview-date
                v-if="
                  item?.crossed_interview &&
                  item?.crossed_interview?.status !== InterviewStatuses.SUBMITTED &&
                  campaign?.campaign_type === CampaignTypeBackend?.ONE_TO_ONE
                "
                :campaign-id="item?.crossed_interview?.campaign_id"
                :cross-id="item?.crossed_interview?.id"
                :date="item?.crossed_interview?.date"
                :end-date="item?.crossed_interview?.ends_at"
                :start-date="item?.crossed_interview?.starts_at"
                @callback="callbackAfterShiftCrossReviewDate"
              >
                <template #activator="{ props }">
                  <v-list-item :active="false" v-bind="props" value="schedule_meeting">
                    {{ $t('Schedule a meeting') }}
                  </v-list-item>
                </template>
              </pop-up-shift-cross-interview-date>

              <!-- Unlock Employee interview -->
              <v-list-item
                v-if="
                  item?.employee_interview &&
                  item?.employee_interview?.status === 'submitted' &&
                  overview === 'overview'
                "
                :active="false"
                :disabled="
                  item?.crossed_interview !== undefined &&
                  item?.crossed_interview?.status === 'submitted'
                "
                value="unlock_one"
                @click="unlockInterview(item?.employee_interview?.id, 'Interviewee')"
              >
                {{
                  $t(
                    `Unlock ${setUnlockText(item?.employee_interview)[0]} review (${setUnlockText(item?.employee_interview)[1]})`,
                  )
                }}
              </v-list-item>

              <!-- Submit Employee interview -->
              <v-list-item
                v-if="
                  (currentUser?.accessLevel.value === 'admin' ||
                    currentUser?.accessLevel.value === 'super_admin') &&
                  item?.employee_interview &&
                  item?.employee_interview?.status !== 'submitted' &&
                  overview === 'overview'
                "
                :active="false"
                :disabled="
                  item?.crossed_interview !== undefined &&
                  item?.crossed_interview?.status === 'submitted'
                "
                value="lock_one"
                @click="lockInterview(item?.employee_interview?.id, 'Interviewer')"
              >
                {{
                  $t(
                    `Submit ${setLockText(item?.employee_interview)[0]} review (${setLockText(item?.employee_interview)[1]})`,
                  )
                }}
              </v-list-item>

              <!-- Unlock Manager interview -->
              <v-list-item
                v-if="
                  item?.manager_interview &&
                  item?.manager_interview?.status === 'submitted' &&
                  overview === 'overview'
                "
                :active="false"
                :disabled="
                  item?.crossed_interview !== undefined &&
                  item?.crossed_interview?.status === 'submitted'
                "
                value="unlock_two"
                @click="unlockInterview(item?.manager_interview?.id, 'Interviewer')"
              >
                {{
                  $t(
                    `Unlock ${setUnlockText(item?.manager_interview)[0]} review (${setUnlockText(item?.manager_interview)[1]})`,
                  )
                }}
              </v-list-item>

              <!-- Submit Manager interview -->
              <v-list-item
                v-if="
                  (currentUser?.accessLevel.value === 'admin' ||
                    currentUser?.accessLevel.value === 'super_admin') &&
                  item?.manager_interview &&
                  item?.manager_interview?.status !== 'submitted' &&
                  overview === 'overview'
                "
                :active="false"
                :disabled="
                  item?.crossed_interview !== undefined &&
                  item?.crossed_interview?.status === 'submitted'
                "
                value="lock_two"
                @click="lockInterview(item?.manager_interview?.id, 'Interviewer')"
              >
                {{
                  $t(
                    `Submit ${setLockText(item?.manager_interview)[0]} review (${setLockText(item?.manager_interview)[1]})`,
                  )
                }}
              </v-list-item>

              <!-- Unlock Cross -->
              <v-list-item
                v-if="
                  item?.crossed_interview &&
                  item?.crossed_interview?.status === 'submitted' &&
                  overview === 'overview'
                "
                :active="false"
                value="unlock_cross"
                @click="unlockInterview(item?.crossed_interview?.id, 'Cross')"
              >
                {{ $t('Unlock Cross Review (CR)') }}
              </v-list-item>

              <!-- Submit Cross -->
              <v-list-item
                v-if="
                  (currentUser?.accessLevel.value === 'admin' ||
                    currentUser?.accessLevel.value === 'super_admin') &&
                  item?.crossed_interview &&
                  item?.crossed_interview?.status !== 'submitted' &&
                  item?.crossed_interview?.status !== 'not_available_yet' &&
                  item?.employee_interview &&
                  item?.employee_interview?.status === 'submitted' &&
                  item?.manager_interview &&
                  item?.manager_interview?.status === 'submitted' &&
                  overview === 'overview'
                "
                :active="false"
                value="lock_cross"
                @click="lockInterview(item?.crossed_interview?.id, 'Cross')"
              >
                {{ $t('Submit Cross Review (CR)') }}
              </v-list-item>

              <!-- Remove from campaign -->
              <svn-pro-dialog-validation
                v-if="overview === 'overview'"
                :action-one-title="$t('Remove')"
                :action-two-title="$t('Cancel')"
                :content-text="$t('This participant will no longer have be part of this campaign')"
                :title="$t('User will be removed from campaign')"
                icon="noto:warning"
                @click-primary-button="removeFromCampaign(item?.employee?.id, item?.id)"
              >
                <template #activator="{ props }">
                  <v-list-item
                    :active="false"
                    class="text-error"
                    v-bind="props"
                    value="remove_from_campaign"
                  >
                    {{ $t('Remove from campaign') }}
                  </v-list-item>
                </template>
              </svn-pro-dialog-validation>
            </template>
          </svn-pro-menu>
        </td>
      </tr>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        data-testid="no-data"
        :size="!isMdScreen ? 'compact' : 'default'"
        :supporting-text="
          search
            ? $t('Oops, we didn’t find any results matching your search.')
            : $t('Hmm, it seems this campaign has no participant yet.')
        "
        :title="$t('Nothing to show for now')"
        :variant="search ? 'results' : 'index'"
        actions
      />
    </template>

    <template #loading></template>
  </svn-pro-data-table>

  <svn-pro-dialog-validation
    ref="sendInvitation"
    :action-one-title="$t('Send')"
    :action-two-title="$t('Cancel')"
    :content-text="$t('An email will be sent to this participant with a link to his interview.')"
    :title="$t('Send the interview ?')"
    @click-primary-button="sendInvitationOrReminder"
  >
    <template #activator>
      {{ null }}
    </template>
  </svn-pro-dialog-validation>

  <pop-up-set-interview
    ref="setInterviewRef"
    :crossed-interview="interviewToPreview?.crossed_interview"
    :employee="interviewToPreview?.employee"
    :employee-interview="interviewToPreview?.employee_interview"
    :interviewer="interviewToPreview?.interviewer"
    :manager-interview="interviewToPreview?.manager_interview"
  >
    <template #activator>
      {{ null }}
    </template>
  </pop-up-set-interview>
</template>

<script setup>
import { ref } from 'vue';
import i18n from '@/plugins/i18n';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import useTools from '@/tools/useTools.js';
import { useUserStore } from '@/store/user';
import { useSnackbar } from '@/store/snackbar.js';
import { useRoute, useRouter } from 'vue-router';
import { useMobileStore } from '@/store/mobile.js';
import axiosService from '@/tools/axios-service.js';
import { InterviewStatuses } from '@/constants/types';
import { useCampaignStore } from '@/store/campaign.js';
import { useFeedbackStore } from '@/store/feedback.js';
import { CampaignTypeBackend } from '@/constants/CampaignTypeEnum.js';
import InterviewStatus from '../../../components/interviewApp/InterviewStatus.vue';
import CampaignStatusChip from '../../../components/campaign/CampaignStatusChip.vue';
import PopUpManagePairs from '../../../components/popUpComponents/PopUpManagePairs.vue';
import PopUpSetInterview from '../../../components/popUpComponents/PopUpSetInterview.vue';
import PopUpSetAnotherInterviewer from '../../../components/popUpComponents/PopUpSetAnotherInterviewer.vue';
import PopUpShiftCrossInterviewDate from '../../../components/popUpComponents/PopUpShiftCrossInterviewDate.vue';

const { currentUser } = storeToRefs(useUserStore());
const { isMdScreen } = storeToRefs(useMobileStore());
const { campaign } = storeToRefs(useCampaignStore());

const { t } = useI18n();
const router = useRouter();
const snackbar = useSnackbar();
const { fetchPage } = useTools();
const { deleteFeedback } = useFeedbackStore();
const { updateParams, fetchCampaign, fetchCampaignInterview, fetchCampaignFeedback } = useCampaignStore();

const props = defineProps({
  set: { type: Array, required: true },
  search: { type: String, default: '' },
  overview: { type: String, default: null },
  campaign: { type: Object, default: null },
  anonymous: { type: Boolean, default: true },
  isTrashed: { type: Boolean, default: true },
  pagination: { type: Object, required: true },
});

const emit = defineEmits(['refetch-interview']);

const route = useRoute();
const loading = ref(false);
const emailParams = ref({});
const setInterviewRef = ref(null);
const sendInvitation = ref(false);
const interviewToPreview = ref(null);
const headers = ref([
  {
    align: 'start',
    key: 'fullname',
    sortable: true,
    title: i18n.global.t('Participants'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'interview_form',
    sortable: false,
    title: i18n.global.t('Template'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'person_in_charge',
    sortable: false,
    title: i18n.global.t('Person in charge'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'completion',
    sortable: false,
    title: i18n.global.t('Completion'),
    width: 158,
    minWidth: 158,
  },
  {
    align: 'start',
    key: 'buttons',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
]);
const feedback_headers = ref([
  {
    align: 'start',
    key: 'fullname',
    sortable: true,
    title: i18n.global.t('Participants'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'interview_form',
    sortable: false,
    title: i18n.global.t('Template'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'person_in_charge',
    sortable: false,
    title: i18n.global.t('Person in charge'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'answers',
    sortable: false,
    title: i18n.global.t('Answers'),
    width: 158,
    minWidth: 158,
  },
  {
    align: 'start',
    key: 'buttons',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
]);
const survey_headers = ref([
  {
    align: 'start',
    key: 'fullname',
    sortable: false,
    title: i18n.global.t('Participants'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'job_title',
    sortable: false,
    title: i18n.global.t('Job title'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'interview_form',
    sortable: false,
    title: i18n.global.t('Template'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'completion',
    sortable: false,
    title: i18n.global.t('Answers'),
    width: 158,
    minWidth: 158,
  },
  {
    align: 'start',
    key: 'buttons',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
]);

const getTagType = (count, totalCount) => {
  if (count === 0) {
    return 'error';
  } else if (count > 0 && count < totalCount) {
    return 'warning';
  } else if (count === totalCount) {
    return 'success';
  } else {
    return 'info';
  }
};

const sendInvitationOrReminder = async () => {
  try {
    await axiosService.get(
      `/interview_app/campaigns/${props?.campaign?.id || route?.params?.id}/send_notification_email`,
      {
        params: emailParams?.value,
      },
    );
    snackbar
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[88px]')
      .setBgColor('onSurface')
      .displaySnackBar(
        emailParams?.value.email_type === 'invite'
          ? 'Invitation is on its way!'
          : 'Reminder is on its way!',
      );
  } catch (error) {
    snackbar
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[88px]')
      .setBgColor('onSurface')
      .displaySnackBar(
        emailParams?.value.email_type === 'invite'
          ? 'Error sending invitation.'
          : 'Error sending reminder.',
      );
  }
};

const setUnlockText = (interview) => {
  if (interview?.label === 'Employee') {
    return ['Interviewee', 'IE'];
  } else {
    return ['Interviewer', 'IR'];
  }
};

const setLockText = (interview) => {
  if (interview?.label === 'Employee') {
    return ['Interviewee', 'IE'];
  } else {
    return ['Interviewer', 'IR'];
  }
};

const sendNotif = (emailType, employeeId = null, feedbackId = null) => {
  sendInvitation.value.dialogRef.dialog = true;
  emailParams.value = {
    email_type: emailType,
    user_id: employeeId,
    feedback_id: feedbackId,
  };
};

const callbackAfterShiftCrossReviewDate = () => {
  fetchPage(updateParams, fetchCampaign(props?.campaign?.id), props?.pagination?.current_page);
};

const removeFromCampaign = async (employeeId, id) => {
  try {
    if (campaign?.value?.campaign_type !== CampaignTypeBackend?.FEEDBACK_360) {
      await axiosService.delete(
        `/interview_app/campaigns/${props?.campaign?.id}/interview_sets/delete_sets.json`,
        {
          params: {
            user_id: employeeId,
          },
        },
      );
    } else {
      await deleteFeedback(id, props?.campaign?.id);
    }
    emit('refetch-interview');
    snackbar
      .setBgColor('onSurface')
      .setCustomClass('mb-4')
      .displaySnackBar('User has been removed successfully.');
  } catch (e) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass('mb-4')
      .displaySnackBar('Error removing user from campaign.');
  } finally {
    fetchPage(updateParams, fetchCurrentCampaign());
  }
};

const unlockInterview = async (id, role) => {
  try {
    document.querySelector('body').classList.add('wait');
    await axiosService.put(`/interview_app/interviews/${id}/unlock.json`).then(() => {
      snackbar.setMsg(t('Review has been unlocked.', { role: role }));
      snackbar.setBgColor('onSurface');
      snackbar.displaySnackBar();
    });
  } catch (e) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass('mb-4')
      .displaySnackBar('Error unlocking interview.');
  } finally {
    document.querySelector('body').classList.remove('wait');
    fetchPage(updateParams, fetchCurrentCampaign(), props?.pagination?.current_page);
  }
};

const lockInterview = async (id, role) => {
  try {
    document.querySelector('body').classList.add('wait');
    await axiosService.put(`/interview_app/interviews/${id}/lock.json`).then(() => {
      snackbar.setMsg(t('Review has been submitted.', { role: role }));
      snackbar.setBgColor('onSurface');
      snackbar.displaySnackBar();
    });
  } catch (e) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass('mb-4')
      .displaySnackBar('Error locking interview.');
  } finally {
    document.querySelector('body').classList.remove('wait');
    fetchPage(updateParams, fetchCurrentCampaign(), props?.pagination?.current_page);
  }
};

const customFetchCamp = (page) => {
  updateParams({ 'page[number]': page });
  fetchCurrentCampaign();
};

const fetchCurrentCampaign = async () => {
  switch (props.campaign?.campaign_type) {
    case CampaignTypeBackend.ONE_TO_ONE:
      await fetchCampaignInterview(props.campaign.id);
      return;
    case CampaignTypeBackend.FEEDBACK_360:
      await fetchCampaignFeedback(props.campaign.id);
      return;
    default:
      return;
  }
};

const openModalInterviewStep = (item) => {
  if (props?.isTrashed) return null;

  if (props?.campaign.campaign_type === CampaignTypeBackend?.ONE_TO_ONE) {
    interviewToPreview.value = item;
    setInterviewRef.value.interviewSet.dialog = true;
  } else if (props?.campaign.campaign_type === CampaignTypeBackend?.FEEDBACK_360) {
    router.push({
      name: 'feedback_answer',
      params: { id: item?.id },
    });
  }
};
</script>
