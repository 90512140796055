<template>
  <svn-pro-modal
    ref="modalNewTarget"
    :height="!isMobile ? 516 : ''"
    :title="$t('Edit template tags')"
    :width="!isMobile ? 528 : ''"
  >
    <template #activator="{ props }">
      <slot :props="props" name="activator" />
    </template>
    <template #text>
      <div class="flex flex-col gap-6 items-start">
        <div class="w-full flex flex-row gap-4 items-start">
          <!-- Search -->
          <svn-pro-combobox
            :key="generation"
            v-model="entityTags"
            v-model:search="searchText"
            :clearable="false"
            item-value="id"
            item-title="title"
            :items="companyCategories"
            :placeholder="$t('Search for tags')"
            :return-object="true"
            class="custom-combobox"
            multiple
            :infinite-scroll-loading="infiniteScrollLoading"
            variant="outlined"
            @intersect="loadMoreData"
            @update:model-value="updateOrCreateTag"
            @update:search="refetchTags"
          >
            <template #selection="{ item }">
              <!-- Do not display selected items in the input field -->
            </template>
          </svn-pro-combobox>
        </div>

        <div v-if="entityTags?.length" class="flex flex-row gap-4 flex-wrap">
          <svn-pro-chip
            v-for="entityTag in entityTags"
            :key="entityTag?.id"
            :is-slot-append="true"
            :text="entityTag?.title"
            class=""
          >
            <template #append>
              <Icon
                class="ml-2 cursor-pointer"
                height="18"
                icon="mingcute:close-line"
                width="18"
                @click="removeTag(entityTag)"
              />
            </template>
          </svn-pro-chip>
        </div>

        <svn-pro-empty-states
          v-if="!entityTags?.length"
          :supporting-text="$t('It seems there is no tags added, search for tags and add or create them.')"
          :title="$t('No tags added')"
          :variant="'index'"
          class="528px"
          icon="mingcute:tag-line"
          size="compact"
        />
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { onMounted, ref, watch } from 'vue';
import { useMobileStore } from '@/store/mobile';
import { useSnackbar } from '@/store/snackbar.js';
import axiosService from '@/tools/axios-service.js';
import { debounce } from 'lodash';

const snackbar = useSnackbar();

const { isMobile } = storeToRefs(useMobileStore());

const props = defineProps({
  entityId: { type: Number, required: true },
  route: { type: String, required: true },
  templateCategories: { type: Object, required: true },
});

const emit = defineEmits(['update-interview-forms-tags']);

onMounted(() => {
  fetchEntityTags();
  fetchCompanyTags();
});

const searchText = ref('');
const generation = ref(0);
const pagination = ref({})
const entityTags = ref([]);
const entityTagsOldValue = ref();
const companyCategories = ref([]);
const modalNewTarget = ref(null);
const infiniteScrollLoading = ref(false);

const fetchEntityTags = async () => {
  try {
    entityTags.value = props?.templateCategories;
    entityTagsOldValue.value = props?.templateCategories;
  } catch (e) {
    console.log('error', e);
  }
};

const fetchCompanyTags = async (title = '', page = 1) => {
  try {
    const res = await axiosService.get(`/companies/categories`, {
      params: {
        title: title,
        'page[number]': page
      }
    });

    companyCategories.value = res?.data?.categories;
    pagination.value = res?.data?.meta?.pagination;
  } catch (e) {
    console.log('error', e);
  }
};

const refetchTags = debounce(async (text) => {
  await fetchCompanyTags(text)
}, 300)

const createTag = async (text) => {
  if (companyCategories?.value.find((tag) => tag.title == text)) return;

  if (props?.route === 'forms/templates') {
    try {
      const res = await axiosService.post(
        `/interview_app/forms/templates/${props?.entityId}/tags`,
        { tag: { title: text } },
      );
      companyCategories?.value?.push(res?.data?.category);
      entityTags?.value?.push(res?.data?.category);

      emit('update-interview-forms-tags', props?.entityId, res?.data?.category);
      generation.value = generation.value + 1;
      searchText.value = '';
    } catch (e) {
      console.log("couldn't create a tag", e);
    }
  } else {
    try {
      const res = await axiosService.post(`/interview_app/${props?.route}/${props?.entityId}/create_tags`, { category_title: text });
      companyCategories?.value?.push(res?.data?.category);
      searchText.value = '';
      
      return res?.data?.category;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors?.title?.[0];
      searchText.value = '';
      entityTags.value = entityTagsOldValue.value;
      generation.value = generation.value + 1;
      snackbar.setBgColor('onSurface').setCustomClass('mb-4').displaySnackBar(errorMessage);
    }
  }
};

const toggleSelectedTag = async (tag) => {
  try {
    const { data } = await axiosService.post(`/interview_app/${props?.route}/${props?.entityId}/toggle_tag`, {
      tag: tag,
    });

    if (data) {
      emit('update-interview-forms-tags', props?.entityId, tag);
    }
  } catch (error) {
    console.log(error);
  }
};

const updateOrCreateTag = async (tags) => {
  if (searchText?.value) {
    const newTag = await createTag(searchText?.value);

    tags?.pop()
    if (newTag?.id) {
      tags?.push({
        id: newTag?.id,
        title: newTag?.title,
      })
    }
  }

  if (!searchText?.value && entityTags?.value?.length > entityTagsOldValue?.value?.length) {
    const tagToAdd = tags.find((tag) => {
      return entityTagsOldValue?.value?.every((oldTag) => oldTag.title !== tag.title);
    });
    toggleSelectedTag(tagToAdd);
    entityTagsOldValue.value = entityTags.value;
  } else if (!searchText?.value && entityTags?.value?.length <= entityTagsOldValue?.value?.length) {
    const tagToRemove = entityTagsOldValue.value?.find((oldTag) => {
      return tags.every((tag) => oldTag.title != tag.title);
    });
    toggleSelectedTag(tagToRemove)
    entityTagsOldValue.value = entityTags.value;
  }
  generation.value = generation.value + 1;
};

const removeTag = async (tag) => {
  await toggleSelectedTag(tag);
  entityTags.value = entityTags.value.filter((entityTag) => entityTag.id !== tag.id);
  entityTagsOldValue.value = entityTags.value;
  generation.value = generation.value + 1;
};

const loadMoreData = async() => {
  if (pagination.value?.current_page < pagination.value?.total_pages) {
    infiniteScrollLoading.value = true
    pagination.value.current_page = pagination.value.current_page + 1

    const res = await axiosService.get(`/companies/categories`, {
      params: {
        title: searchText?.value,
        'page[number]': pagination?.value?.current_page
      }
    });
    companyCategories.value = [...companyCategories.value, ...res?.data?.categories];
    pagination.value = res?.data?.meta?.pagination
    infiniteScrollLoading.value = false;
  }
}

defineExpose({
  modalNewTarget,
});
</script>