<template>
  <div>
    <!-- Top Bar Component -->
    <div class="!sticky top-[64px] z-[25]">
      <svn-pro-top-bar
        :has-primary-button="false"
        :has-secondary-button="false"
        :has-close-button="true"
        :has-left-content="false"
        :has-info-tag="false"
        :icon-buttons-number="1"
        :icon-buttons-array="[
          {
            icon: 'custom:mingcute:download-2-line',
            onClick: () => downloadEvaluationPdf(),
          },
        ]"
        @onClose="navigateBackToModule"
      />
     </div>

    <!-- Main Content -->
    <div class="flex flex-col justify-center items-center xs:mt-6 xs:mx-5 sm:mx-12 md:mx-auto md:max-w-[560px] mb-24">
      <!-- Evaluation Title -->
      <div class="mb-2 text-center">
        <svn-pro-title h4 medium>{{ $t('Face-to-face evaluation') }}</svn-pro-title>
      </div>

      <div class="mb-6 text-center">
        <svn-pro-title color="onSurfaceVariant" h5 medium>
          {{ route.query.moduleTitle }}
        </svn-pro-title>
      </div>

      <!-- Questions List -->
      <div v-if="evaluationData?.learnInputs?.length" :key="resetKey" class="space-y-6 w-full">
        <svn-pro-form-block
          v-for="(input, index) in evaluationData?.learnInputs"
          :key="input?.id"
          :ref="(el) => (fieldRefs[index] = el)"
          :selected-value="fetchInputAnswer(input?.id)"
          :has-comment-area="false"
          :description="input.description"
          :input-label="'Answer*'"
          :question-type="'InterviewApp::Questions::Open'"
          :size="isCompact ? 'compact' : 'default'"
          :state="inputValidationStates[input.id]"
          :title="input.title"
          @update-answer="updateAnswerForInput(input, $event)"
        />
        <!-- Expert Selection -->
        <svn-pro-form-block
          ref="expertSelection"
          :description="$t('Please select the expert who will review your answers. After validation, they will receive a copy of this evaluation by email.')"
          :hasEditableArea="false"
          :hasVariableContent="true"
          :input-label="'Answer*'"
          :question-type="'InterviewApp::Questions::Mcq'"
          :size="isCompact ? 'compact' : 'default'"
          :state="expertErrorState ? 'error' : 'default'"
          :title="$t('Select your expert')"
          :variableContent="true"
        >
          <template #variable-content>
            <svn-pro-autocomplete
              v-if="evaluationAnswers"
              v-model="selectedExpert"
              :clearable="false"
              :items="users"
              :label="$t('My expert')"
              item-title="fullname"
              item-value="id"
              @clear="clearSearch"
              @input="searchExpert"
              @intersect="fetchMoreUsers"
              @update:model-value="submitSelectedExpert"
            />
          </template>
        </svn-pro-form-block>
      </div>

      <div v-else class="w-full space-y-4">
        <svn-pro-section-quiz-learn-skeleton />
        <svn-pro-section-quiz-learn-skeleton />
        <svn-pro-section-quiz-learn-skeleton />
      </div>

      <!-- Submit button -->
      <div class="w-full flex flex-row justify-center mt-6">
        <svn-pro-button
          :loading="submitLoading"
          :text="$t('Submit answers')"
          variant="flat"
          @click="submitAnswers"
        />
      </div>
    </div>

    <!-- Floating Action Button (FAB) -->
    <svn-pro-floating-action-button
      id="fab"
      :rounded="'lg'"
      class="fixed bottom-4 right-4 bg-white"
      color="primary"
      icon="custom:mingcute:arrow-to-up-line"
      size="small"
      to-top
      variant="tonal"
    />

    <!-- Dialog Validation for Permanent Deletion -->
    <svn-pro-dialog-validation
      v-if="evaluationAnswers?.expert"
      ref="congratDialogRef"
      :action-one-title="isThereANextModule ? $t('Next module') : $t('Go to catalog')"
      :action-two-title="$t('Cancel')"
      :content-text="
        $t('Congratulations, keep it up! Your answers have been sent to', {
          user: firstname,
          expertFirstname: evaluationAnswers.expert.firstname,
          expertLastname: evaluationAnswers.expert.lastname,
        })
      "
      :title="$t('Module acquired!')"
      icon="noto:party-popper"
      @click-primary-button="navigateToNextModuleOrCatalog"
      @click-secondary-button="reopenCurrentModule"
    >
      <template #activator>
        {{ null }}
      </template>
    </svn-pro-dialog-validation>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { debounce } from 'lodash';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useSnackbar } from '@/store/snackbar.js';
import { useUserStore } from '@/store/user';
import { faceToFaceEvaluationApi, faceToFaceSubmissionApi } from '@/apis/face-to-face.api';
import { downloadPdf, downloadPdfWithoutToast } from '@/tools/pdf-proxy-service.js';
import { useFormErrorScroll } from '@/tools/useFormErrorScroll.js';
import ModuleApi from '@/apis/module.api';
import { answerApi } from '../../../apis/answer.api';
import { TopBarLearnModuleTypes } from 'svn-ui-library/components';
import { useBreakpointsSeven } from '@/tools/breakpoints';

const breakpointsSeven = useBreakpointsSeven();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { searchEmployess } = useUserStore();

const evaluationData = ref(null);
const evaluationAnswers = ref(null);
const isCompact = ref(false);
const inputValidationStates = ref({});
const selectedExpertId = ref(null);
const expertRef = ref(null);
const users = ref([]);
const congratDialogRef = ref(null);
const expertErrorState = ref(false);
const downloadLoading = ref(false);
const submitLoading = ref(false);
const resetKey = ref(0);

const { fieldRefs, scrollToFirstError } = useFormErrorScroll();
const module = ref(null);
const { firstname } = storeToRefs(useUserStore());

onMounted(async () => {
  if (!route.params.id) return router.back();

  await loadEvaluationData();

  if (evaluationAnswers.value?.status == 'acquired') {
    await clearForm();
  }
  evaluationAnswers.value.expert = validateExpert(evaluationAnswers.value?.expert);

  if (route.query.trainingId) {
    await fetchModulePaginationByTraining();
  }

  window.addEventListener('resize', updateSize);
  updateSize();
});
const validateExpert = (expert) => {
  if (!expert || !expert.firstname || !expert.lastname) {
    return undefined;
  }
  return expert;
};

const selectedExpert = computed({
  get() {
    const expert = evaluationAnswers.value?.expert;

    if (expert && expert.firstname && expert.lastname) {
      return expert;
    }
    return null;
  },
  set(newExpert) {
    evaluationAnswers.value.expert = newExpert;
  },
});
const clearForm = async () => {
  const updatedAnswers = evaluationAnswers.value.learnResponsesAnswers.map((answer) => {
    return {
      ...answer,
      text: '',
    };
  });

  evaluationAnswers.value.learnResponsesAnswers = updatedAnswers;
  evaluationAnswers.value.expert = null;

  Object.keys(inputValidationStates.value).forEach((key) => {
    inputValidationStates.value[key] = 'default';
  });

  resetKey.value++;

  try {
    await faceToFaceSubmissionApi.patchSubmission(route.params.id, {
      reset: true,
    });
  } catch (error) {
    console.error('Error resetting data:', error);
  }
};

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateSize);
});

const updateSize = () => {
  isCompact.value = breakpointsSeven.smaller('sm').value;
};

const loadEvaluationData = async () => {
  try {
    const data = await searchEmployess();
    users.value = data?.users;

    evaluationData.value = await faceToFaceEvaluationApi.get(route.params.id);
    evaluationAnswers.value = await faceToFaceSubmissionApi.getSubmission(route.params.id);
  } catch (e) {
    console.error('Error fetching evaluation:', e);
  }
};

const fetchModulePaginationByTraining = async () => {
  try {
    module.value = await ModuleApi.paginateByTraining(route.query.moduleId, route.query.trainingId);
  } catch (e) {
    console.log('pagination by training error', e);
  }
};

const isThereANextModule = computed(() => {
  return route.query.trainingId && module.value?.pagination?.nextModuleId;
});

const searchExpert = debounce(async (search) => {
  const data = await searchEmployess(search);
  users.value = data.users;
}, 300);

const clearSearch = debounce(async () => {
  const data = await searchEmployess('');
  users.value = data.users;
}, 300);

const fetchMoreUsers = async () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
    try {
      const data = await searchEmployess('', currentPage.value);
      users.value = [...users.value, ...data.users];
    } catch (error) {
      console.log(error);
    }
  }
};

const submitSelectedExpert = async () => {
  try {
    selectedExpertId.value = 0;
    const result = await faceToFaceSubmissionApi.patchSubmission(route.params.id, {
      expert_id: evaluationAnswers.value?.expert,
    });

    evaluationAnswers.value.expert = result.data.expert;
    evaluationData.value.expert = result.data.expert;
  } catch (error) {
    console.error('Error submitting data:', error);
  }
};

const updateAnswerForInput = debounce(async (input, text) => {
  try {
    const matchingAnswer = evaluationAnswers.value?.learnResponsesAnswers.find(
      (answer) => answer.inputId === input.id,
    );

    if (matchingAnswer) {
      matchingAnswer.text = text;
      await answerApi.patch(matchingAnswer.id, matchingAnswer);
    } else {
      console.error('Unable to find the corresponding answer');
    }
  } catch (error) {
    console.error('Error submitting data:', error);
  }
}, 300);

const fetchInputAnswer = (inputId) => {
  const matchingAnswer = evaluationAnswers.value?.learnResponsesAnswers?.find(
    (answer) => answer.inputId === inputId,
  );
  return matchingAnswer?.text || '';
};

const downloadEvaluationPdf = debounce(async () => {
  downloadLoading.value = true;
  try {
    downloadPdf(
      `/pdf/face_to_face_answer/${route.query.moduleId}/${route.query.moduleEditor}`,
      `face_to_face_evaluation${route.query.moduleId}`,
    );
  } catch (error) {
    console.log(error);
  } finally {
    downloadLoading.value = false;
  }
}, 300);

const downloadEvaluationPdfWithoutToast = async () => {
  try {
    const res = await downloadPdfWithoutToast(
      `/pdf/face_to_face_answer/${route.query.moduleId}/${route.query.moduleEditor}`,
      `face_to_face_evaluation${route.query.moduleId}`,
    );

    let pdfName = null;

    if (import.meta.env.PDF_PROXY_DOMAIN) {
      let pdfProxyUrl = import.meta.env.PDF_PROXY_DOMAIN.replace('/pdf', '') + '/';
      pdfProxyUrl = pdfProxyUrl.replace('http:', 'https:');

      let pdfUrl = res?.href.replace('http:', 'https:');
      pdfName = pdfUrl.replace(pdfProxyUrl, '');
    }
    return pdfName;
  } catch (error) {
    console.log(error);
  }
};

const submitAnswers = debounce(async () => {
  try {
    submitLoading.value = true;
    clearFormErrors();
    const pdfName = await downloadEvaluationPdfWithoutToast();
    const isFormValid = validateForm();

    if (!isFormValid) {
      await scrollToFirstError(Object.values(inputValidationStates.value), [expertRef.value]);
      snackbar
        .setStatus('warning')
        .setCustomClass(window.innerWidth <= 800 ? 'mb-[72px]' : '')
        .displaySnackBar(t('Please fill all required fields.'));
      return;
    }

    const result = await faceToFaceSubmissionApi.verify(
      route.params.id,
      evaluationAnswers.value?.learnResponsesAnswers,
      pdfName,
    );
    congratDialogRef.value.dialogRef.dialog = true;
  } catch (error) {
    console.log(error);
  } finally {
    submitLoading.value = false;
  }
}, 300);

const validateForm = () => {
  let isValid = true;

  evaluationData.value.learnInputs.forEach((input) => {
    if (!fetchInputAnswer(input.id)) {
      inputValidationStates.value[input.id] = 'error';
      isValid = false;
    }
  });

  if (evaluationData.value.expert == undefined && evaluationAnswers.value.expert == undefined) {
    expertErrorState.value = true;
    isValid = false;
  }

  return isValid;
};

const clearFormErrors = () => {
  evaluationData.value.learnInputs.forEach((input) => {
    inputValidationStates.value[input.id] = 'default';
  });
  expertErrorState.value = false;
};

const navigateToNextModuleOrCatalog = () => {
  router.push({
    name: 'module_show',
    params: {
      id: route.query.moduleId,
    },
    query: {
      trainingId: route?.query?.trainingId,
      nextModuleId: module.value?.pagination?.nextModuleId,
      returnAction: 'module-validated',
      redirectCatalog: isThereANextModule.value ? true : false,
    },
  });
};

const navigateBackToModule = () => {
  router.push({
    name: 'module_show',
    params: {
      id: route.query.moduleId,
    },
    query: {
      trainingId: route?.query?.trainingId,
      returnAction: 'module-saved',
    },
  });
};

const reopenCurrentModule = () => {
  router.push({
    name: 'module_show',
    params: {
      id: route.query.moduleId,
    },
    query: {
      trainingId: route?.query?.trainingId,
      returnAction: 'module-validated',
    },
  });
};
</script>
