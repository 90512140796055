import { TAB_TYPES } from '../constants/types'; // Importing enumeration for tab types to ensure consistency and avoid magic strings.

/**
 * A utility hook for handling interview-related logic, specifically for retrieving
 * avatar URLs and text values based on the tab type and item data.
 */
export const useInterviewUtils = () => {
  /**
   * Retrieves the text of the cell as 'participant'.
   *
   * @param {Object} item - The data object representing an interview.
   * @returns {string|null} - The value of the text, or `null` if the campaign type matches nothing.
   */
  const getTextParticipantByCampaignType = (item) => {
    switch (item?.campaign?.campaign_type) {
      case 'OneToOne':
        return item?.interviewer?.fullname;
      case 'Feedback':
        return item?.interviewer?.fullname;
      case 'Survey':
        return item?.survey_person_in_charge?.length > 1
          ? 'Multiple person in charge'
          : item?.survey_person_in_charge?.[0]?.fullname;
      default:
        return '';
    }
  };

  /**
   * Retrieves the text of the cell as 'reviewer'.
   *
   * @param {Object} item - The data object representing an interview.
   * @returns {string|null} - The value of the text, or `null` if the campaign type matches nothing.
   */
  const getTextReviewerByCampaignType = (item) => {
    switch (item?.campaign?.campaign_type) {
      case 'OneToOne':
        return '';
      case 'Feedback':
        return item?.participant?.fullname;
      case 'Survey':
        return '';
    }
  };

  /**
   * Retrieves the text of the cell as 'person in charge'.
   *
   * @param {Object} item - The data object representing an interview.
   * @returns {string|null} - The value of the text, or `null` if the campaign type matches nothing.
   */
  const getTextPersonInChargeByCampaignType = (item) => {
    switch (item?.campaign?.campaign_type) {
      case 'OneToOne':
        return item?.employee?.fullname || 'Interviewee';
      case 'Feedback':
        return item?.employee?.fullname || 'Participant';
      case 'Survey':
        return item?.survey_participants?.length > 1
          ? 'Multiple participants'
          : item?.survey_participants?.[0]?.fullname;
      default:
        return '';
    }
  };

  /**
   * Retrieves the avatar URL for a specific tab and item.
   *
   * @param {Object} item - The data object representing an interview.
   * @param {string} tab - The current tab type (one of the values from TAB_TYPES).
   * @returns {string|null} - The avatar URL, or `null` if no valid avatar exists for the tab.
   */
  const getAvatar = (item, tab) => {
    // Mapping tab types to corresponding avatar sources within the item object.
    const avatars = {
      [TAB_TYPES.AS_PARTICIPANT]: item?.employee?.avatar?.['30'], // Avatar for "as participant" tab.
      [TAB_TYPES.AS_REVIEWER]: item?.participant?.avatar?.['30'], // Avatar for "as reviewer" tab.
      [TAB_TYPES.AS_PERSON_IN_CHARGE]: item?.interviewer?.avatar?.['30'], // Avatar for "as person in charge" tab.
    };

    // Return the avatar URL for the given tab or `null` if no avatar is available.
    return avatars[tab] || null;
  };

  /**
   * Retrieves the display text (e.g., full name) for a specific tab and item.
   *
   * @param {Object} item - The data object representing an interview.
   * @param {string} tab - The current tab type (one of the values from TAB_TYPES).
   * @returns {string|null} - The display text (full name), or `null` if no valid text exists for the tab.
   */
  const getText = (item, tab) => {
    if (tab === TAB_TYPES.AS_PARTICIPANT) {
      return getTextParticipantByCampaignType(item);
    } else if (tab === TAB_TYPES.AS_REVIEWER) {
      return getTextReviewerByCampaignType(item);
    } else if (tab === TAB_TYPES.AS_PERSON_IN_CHARGE) {
      return getTextPersonInChargeByCampaignType(item);
    } else {
      return '';
    }
  };

  const getLabel = (item) => {
    if (!item?.status) return 'Not started';
    const statusMap = {
      not_available_yet: 'Not available yet',
    };

    let label = statusMap[item.status] || item.status;

    return label;
  };

  // Expose the utility functions for use in other components or hooks.
  return {
    getAvatar,
    getText,
    getLabel,
  };
};
