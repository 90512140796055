export const interviewFormTypeToStr = {
  employee: 'Interviewee (IE)',
  manager: 'Interviewer (IR)',
  both: 'Both',
  cross: 'Cross review',
  pairs: 'Feedback 360',
  survey: 'Survey'
}

export const MessageRoleToStr = {
  user: 'user',
  system: 'system',
}

export const AccessLevelList = [
  {
    title: "Employee",
    value: "employee",
  },
  {
    title: "Manager",
    value: "manager",
  },
  {
    title: "Manager creator",
    value: "manager_creator",
  },
  {
    title: "Admin",
    value: "admin",
  },
]
export const AccessLevelListSuperAdmin = [
  {
    title: "Employee",
    value: "employee",
  },
  {
    title: "Manager",
    value: "manager",
  },
  {
    title: "Manager creator",
    value: "manager_creator",
  },
  {
    title: "Admin",
    value: "admin",
  },
  {
    title: "Super admin",
    value: "super_admin",
  },
]

export const InterviewQuestionType = {
  CHAPTER: 'InterviewApp::Questions::Chapter',
  PARAGRAPH: 'InterviewApp::Questions::Paragraph',
  OPEN: 'InterviewApp::Questions::Open',
  RATING: 'InterviewApp::Questions::Rating',
  MCQ: 'InterviewApp::Questions::Mcq',
  CREATE_ROADMAP: 'InterviewApp::Questions::CreateRoadmap',
  UPDATE_ROADMAP: 'InterviewApp::Questions::UpdateRoadmap',
}

export const LearnInputType = {
  CHECKBOX: 'Learn::Inputs::CheckboxQuestion',
  CONFIRMATION: 'Learn::Inputs::Confirmation',
  OPEN: 'Learn::Inputs::OpenQuestion',
  PARAGRAPH: 'Learn::Inputs::Paragraph',
  SIMPLE_TEXT: 'Learn::Inputs::SimpleText',
  MODULE_AI_RESPONE_CHANNEL: "Learn::Modules::AiResponseChannel",
}

export const LearnModuleChannels = {
  AI_RESPONSE_CHANNEL: "Learn::Modules::AiResponseChannel",
}

export const LearnModuleStatus = {
  draft: 'draft',
  published: 'published',
  trash: 'trash',
}

export const InterviewOneToOneLabels = {
  INTERVIEWEE: 'Employee',
  INTERVIEWER: 'Manager',
  CROSSED: 'Crossed',
}

export const InterviewStatuses = {
  NOT_STARTED: 'not_started',
  NOT_AVAILABLE_YET: 'not_available_yet',
  IN_PROGRESS: 'in_progress',
  SUBMITTED: 'submitted',
  DISABLED: 'disabled',
}

export const TAB_TYPES = Object.freeze({
  AS_PARTICIPANT: "as_participant",
  AS_REVIEWER: "as_reviewer",
  AS_PERSON_IN_CHARGE: "as_person_in_charge",
});

export const TAG_TYPES = Object.freeze({
  NOT_STARTED: "error",
  IN_PROGRESS: "warning",
  SUBMITTED: "success",
  DEFAULT: "info",
});

export const EMPTY_STATES_VARIANT = Object.freeze({
  RESULTS: "results",
  INDEX: "index",
});

export const EMPTY_STATES_SIZE = Object.freeze({
  DEFAULT: "default",
  COMPACT: "compact",
});

export const MENTIONS = Object.freeze({
  "Person in charge": "as participant",
  "Review of": "as reviewer",
  "In charge of": "as person in charge",
});


export const ModelNames = {
  USER: 'User',
  LEARN_MODULE: 'Learn::Module',
  LEARN_TRAINING: 'Learn::Training',
  OBJECTIVE_ELEMENT: 'Objective::Element',
  INTERVIEW_CAMPAIGN: 'InterviewApp::Campaign',
  INTERVIEW_FORM: 'InterviewApp::Form',
};

export const campaignTypes = {
  OneToOne: 'InterviewApp::Campaigns::OneToOne',
  Feedback: 'InterviewApp::Campaigns::Feedback',
  Survey: 'InterviewApp::Campaigns::Survey',
}