export const strMode = (mode) => {
  const modeMap = {
    analytics: "Analytics",
    data: "Global data",
    answers: "Answers",
    roadmap: "Roadmap",
  };
  return modeMap[mode] || "Unknown";
};

export const downloadWithCustomName = (url, mode) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const reportType = strMode(mode); // Utilise strMode pour obtenir la description du mode
      const date = new Date().toISOString().split("T")[0];
      const newFileName = `Campaign Report (${reportType}) - ${date}.xlsx`;

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = newFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(console.error);
};
