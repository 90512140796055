<template>
  <div>
    <div class="!sticky top-[64px] z-[25]">
      <svn-pro-top-bar
        :has-primary-button="false"
        :has-secondary-button="false"
        :has-close-button="true"
        :has-left-content="false"
        :has-info-tag="false"
        :icon-buttons-number="0"
        @onClose="preventQuit.dialogRef.dialog = true"
      />
    </div>

    <div
      class="flex flex-col justify-center items-center xs:mt-6 xs:mx-5 xs:mb-24 sm:mx-12 md:mx-auto md:max-w-[560px]"
    >
      <div class="mb-2">
        <svn-pro-title h4 medium>
          {{ $t('Quiz') }}
        </svn-pro-title>
      </div>

      <div class="mb-6">
        <svn-pro-title class="text-center" h5 medium color="onSurfaceVariant">
          {{ route.query.moduleTitle }}
        </svn-pro-title>
      </div>

      <div v-if="quiz != null" class="space-y-6 w-full">
        <svn-pro-section-quiz-learn-base
          v-for="input in quiz.learnInputs.filter((item) => item.isCheckBoxQuestion())"
          :key="input.id"
          :correctionType="SvnProSectionQuizLearnCorrectionType.none"
          :list="input.proposals"
          :title="input.title"
        >
          <template #default="{ item, index }">
            <svn-pro-quiz-answer-learn-item
              :leftLetter="alphabetFromIndex(index)"
              :optionText="item.proposal"
              :state="
                isProposalInToSend(input.id, item.proposal)
                  ? SvnProQuizAnswerLearnItemStates.selected
                  : SvnProQuizAnswerLearnItemStates.enable
              "
              @click="selectAnswer($event, input.id, item.proposal)"
            ></svn-pro-quiz-answer-learn-item>
          </template>
        </svn-pro-section-quiz-learn-base>
      </div>
      <div v-else class="w-full space-y-4">
        <svn-pro-section-quiz-learn-skeleton />
        <svn-pro-section-quiz-learn-skeleton />
        <svn-pro-section-quiz-learn-skeleton />
      </div>

      <div class="w-full flex flex-row justify-center mt-6">
        <svn-pro-button
          :loading="loadingValidation"
          variant="flat"
          :text="$t('Validate answers')"
          @click="verifySubmission"
        />
      </div>
    </div>

    <!--    dialog teleport in order to prevent quit-->
    <svn-pro-dialog-validation
      ref="preventQuit"
      icon="noto:warning"
      :action-two-title="$t('Cancel')"
      :action-one-title="$t('Yes, leave')"
      :title="$t('Your answers will be lost.')"
      :content-text="$t('If you leave the quiz now, your answers will be lost. Are you sure?')"
      @click-primary-button="
        router.push({
          name: 'module_show',
          params: { id: route.query.moduleId },
          query: {
            trainingId: route.query.trainingId,
          },
        })
      "
    >
      <template #activator="{ props }">
        <div class="hidden" />
      </template>
    </svn-pro-dialog-validation>
  </div>
</template>

<script setup>
import {
  TopBarLearnModuleTypes,
  SvnProQuizAnswerLearnItemStates,
  SvnProSectionQuizLearnCorrectionType,
} from 'svn-ui-library/components';
import { useRoute, useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import QuizApi from '../../../apis/quiz.api';
import QuizSubmissionApi from '@/apis/quiz_submission.api';
import tools from '@/tools/caseStyles';
import { useSnackbar } from '@/store/snackbar';
import useStringTools from '@/tools/useStringTools';

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { alphabetFromIndex } = useStringTools();

const quiz = ref(null);
const toSend = ref([]);
const submission = ref(null);
const loadingValidation = ref(false);
const preventQuit = ref(false);

onMounted(async () => {
  if (!route.params.id) return router.back();

  try {
    quiz.value = await QuizApi.get(route.params.id);
  } catch (e) {
    console.log('quiz error', e);
  }

  try {
    submission.value = await QuizSubmissionApi.getSubmission(route.params.id, {
      params: {
        reset: true,
      },
    });
    toSend.value = submission.value.learnResponsesCheckboxes;
  } catch (e) {
    console.log('submission not found', e);
  }
});

const selectAnswer = (event, inputId, proposal) => {
  // don't know why event is fired twice
  if (!event) return;
  const idxInput = toSendIdxInput(inputId);
  if (idxInput === -1) return;

  if (isProposalInToSend(inputId, proposal)) {
    toSend.value[idxInput].selectedValues = toSend.value[idxInput].selectedValues.filter(
      (item) => item !== proposal,
    );
  } else {
    toSend.value[idxInput].selectedValues.push(proposal);
  }
};

const isProposalInToSend = (inputId, proposal) => {
  const idxInput = toSendIdxInput(inputId);
  if (idxInput === -1) return;

  const idxProposal = toSend.value[idxInput].selectedValues.findIndex((item) => item === proposal);

  if (idxProposal === -1) {
    return false;
  } else {
    return true;
  }
};

const toSendIdxInput = (inputId) => {
  return toSend.value.findIndex((item) => item?.inputId === inputId);
};

const verifySubmission = async () => {
  if (!validateToSend()) {
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Please select all required fields.');
    snackbar.displaySnackBar();
    return;
  }

  loadingValidation.value = true;
  let responses = tools.convertKeysToSnake(toSend.value);
  responses = responses?.map((item) => ({ ...item, response_id: item.id }));

  try {
    await QuizSubmissionApi.verify(route.params.id, responses);

    await router.push({
      name: 'quiz_result',
      params: {
        id: route.params.id,
      },
      query: {
        moduleId: route.query.moduleId,
        moduleTitle: route.query.moduleTitle,
        trainingId: route.query.trainingId,
      },
    });
  } catch (e) {
    loadingValidation.value = false;
    console.log('error validation');
  }
};

const validateToSend = () => {
  for (const toSendElement of toSend.value) {
    if (toSendElement?.selectedValues?.length === 0) return false;
  }
  return true;
};
</script>
